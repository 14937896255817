import React, { useRef, useState, useEffect } from "react";
import "./FloorLayoutStyles.scss";
import { useDrop } from "react-dnd";
import { RootState } from "../../../../application/store";
import { useSelector, useDispatch } from "react-redux";
import TableComponent from "./Table/TableComponent";
import Button from "../../button/Button";
import { v4 as uuidv4 } from "uuid";
import globalValues from "../../../../infra/apis/globalValues";
import { setFloorLayoutData } from "../../../../application/reducer/bookingSlice";

interface FloorConfigLayoutProps {
  items: any;
  setSelectedFloor: React.Dispatch<React.SetStateAction<any>>;
  selectedFloor: any;
  addRoom?: () => void;
  setIsReservationWarningOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const FloorConfigLayout: React.FC<FloorConfigLayoutProps> = ({
  items,
  setSelectedFloor,
  selectedFloor,
  addRoom,
  setIsReservationWarningOpen,
}) => {
  const [selectedTable, setSelectedTable] = useState<any>(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  const locationId = useSelector(
    (state: RootState) => state.booking.locationId
  );
  const organizationDetails = useSelector(
    (state: RootState) => state.booking.organizationDetails
  );
  const floorLayoutData = useSelector(
    (state: RootState) => state.booking.floorLayoutData
  );
  const containerRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setSelectedTable(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggedOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggedOver(false);
  };
  const [, drop] = useDrop({
    accept: "table",
    drop: (item: any, monitor) => {
      const containerRect = containerRef?.current?.getBoundingClientRect();
      const offset = monitor.getClientOffset();
      let active = globalValues.environment.ENV !== "production" ? 0 : 1;
      let allTables = floorLayoutData.flatMap((floor) => floor.Tables);
      let tablesCount = selectedFloor.Tables.filter(
        (table: any) => table.IsActive !== active
      ).length;
      const getNewTableName = () => {
        let baseName = (tablesCount + 1).toString();
        let existingNames = allTables
          .filter((table) => table.IsActive !== active)
          .map((table) => table.TableName);
        let counter = 1;
        let newTableName = baseName;

        while (existingNames.includes(newTableName)) {
          newTableName = `${baseName} (${counter})`;
          counter++;
        }

        return newTableName;
      };

      if (selectedFloor === null) return;
      if (!offset || !containerRect) return;

      let minusLeft = item.isNew ? 50 : item.Dimensions.Width;
      let minusTop = item.isNew ? 46.5 : item.Dimensions.Height;

      const left =
        offset.x - containerRect.left < minusLeft
          ? offset.x - containerRect.left
          : offset.x - containerRect.left - minusLeft;
      const top =
        offset.y - containerRect.top < minusTop
          ? offset.y - containerRect.top
          : offset.y - containerRect.top - minusTop;

      const selectedRoomTables = selectedFloor.Tables
        ? [...selectedFloor.Tables]
        : [];

      let newTable = {
        CloudLocationID: locationId,
        Covers: {
          Maximum: item.maxCovers,
          Minimum: 1,
        },
        Dimensions: {
          Angle: 0,
          Height: item.height,
          Shape: item.type,
          TableName: getNewTableName(),
          Width: item.width,
          XValue: Math.round(left),
          YValue: Math.round(top),
        },
        IsOnline: true,
        OrganizationID: organizationDetails.ID,
        Priority: 1,
        TableName: getNewTableName(),
        isNew: item.isNew,
        ID: uuidv4().replace(/-/g, "").substring(0, 24),
        Floor: {
          CloudRefNo: selectedFloor?.FloorID,
        },
        IsActive: globalValues.environment.ENV !== "production" ? 1 : 0,
        Reservations: [],
      };

      setIsDraggedOver(false);

      if (item.isNew) {
        selectedRoomTables.push(newTable);
      } else {
        const index = selectedRoomTables.findIndex(
          (table) => table.ID === item.ID
        );

        if (index !== -1) {
          const updatedTable = {
            ...item,
            Dimensions: {
              ...item.Dimensions,
              XValue: Math.round(left),
              YValue: Math.round(top),
            },
          };
          selectedRoomTables[index] = updatedTable;
        }
      }

      const floorIndex = floorLayoutData.findIndex(
        (item) => item.FloorID === selectedFloor?.FloorID
      );

      if (floorIndex !== -1) {
        let Tables = selectedRoomTables;
        const updatedList = [...floorLayoutData];
        updatedList[floorIndex] = { ...updatedList[floorIndex], Tables };
        setSelectedFloor(updatedList[floorIndex]);
        dispatch(setFloorLayoutData(updatedList));
      }
    },
  });
  return floorLayoutData.length !== 0 ? (
    <div
      className={`FloorLayout ${
        items.length > 0 || !isDraggedOver ? "" : "FloorLayout-empty"
      }`}
      ref={(node) => {
        drop(node);
        if (containerRef) {
          containerRef.current = node;
        }
      }}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      {items.length > 0 || !isDraggedOver ? (
        <div ref={dropdownRef}>
          {items?.map((item: any, index: number) => {
            return (
              <TableComponent
                item={item}
                setSelectedTable={setSelectedTable}
                selectedTable={selectedTable}
                setSelectedFloor={setSelectedFloor}
                selectedFloor={selectedFloor}
                setIsReservationWarningOpen={setIsReservationWarningOpen}
              />
            );
          })}
        </div>
      ) : (
        <div className='empty-cont'>Drag and drop your tables here</div>
      )}
    </div>
  ) : (
    <div className='FloorLayout-blank'>
      <div className='empty-floor'>
        <div className='text'>
          You do not have any rooms added, let's start by adding a room
        </div>
        <Button
          text='Add Room'
          icon
          width='220px'
          height='34px'
          auth
          onClick={addRoom}
        />
      </div>
    </div>
  );
};

export default FloorConfigLayout;
