import React, { useEffect } from "react";
import SearchInput from "../../../components/ReservationComponents/searchInput/SearchInput";
import FilterComponent from "../../../components/ReservationComponents/filterTab/FilterComponent";
import ShiftsFloorView from "../../../components/ReservationComponents/shiftsView/ShiftsFloorView";
import FloorView from "./floorView/FloorView";
import moment from "moment";

interface ReservationFloorViewComponentProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  getshiftsWithBookings: any;
  setSelectedShift: React.Dispatch<React.SetStateAction<string>>;
  unsortedShifts: any;
  selectedShift: string;
  selectedFilter: string;
  setSelectedFilter: React.Dispatch<React.SetStateAction<string>>;
  getSeatedBookings: any;
  setSelectedTable: React.Dispatch<React.SetStateAction<any>>;
  setIsTableView: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDate: Date;
  isOpenDrawer: boolean;
}
const ReservationFloorViewComponent: React.FC<
  ReservationFloorViewComponentProps
> = ({
  value,
  setValue,
  unsortedShifts,
  getshiftsWithBookings,
  setSelectedShift,
  selectedShift,
  selectedFilter,
  setSelectedFilter,
  getSeatedBookings,
  setIsTableView,
  setSelectedTable,
  selectedDate,
  isOpenDrawer,
}) => {
  useEffect(() => {
    setValue("");
  }, [selectedDate]);
  return (
    <div className='floor-view'>
      <div className='left-view'>
        <div
          className='fixed-view'
          style={{ width: isOpenDrawer ? "23%" : "" }}
        >
          <div className='header-container'>
            <div className='content content-selected '>Reservation</div>
            <div className='content'></div>
            <div className='content'></div>
          </div>
          <div className='search-container'>
            <SearchInput
              value={value}
              onChange={(e) => {
                setValue(e);
              }}
            />
          </div>
          <div className='FilterTab-floor'>
            <FilterComponent
              setSelectedShift={setSelectedShift}
              selectedShift={selectedShift}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              unsortedShifts={unsortedShifts}
              view='FLOOR'
            />
          </div>
        </div>

        <div className='table-view'>
          {getshiftsWithBookings().length > 0 ? (
            getshiftsWithBookings()?.map((shift: any) => {
              return (
                <div>
                  <ShiftsFloorView
                    shift={shift?.Name}
                    bookings={getSeatedBookings(shift?.Bookings)}
                    unsortedBookings={shift?.Bookings}
                    setSelectedTable={setSelectedTable}
                    setIsTableView={setIsTableView}
                    selectedFilter={selectedFilter}
                  />
                </div>
              );
            })
          ) : (
            <div className='empty-container'>No bookings available</div>
          )}
        </div>
        {/* <FloorFloatingTab /> */}
      </div>
      <div className='right-view'>
        <FloorView
          date={moment(selectedDate).format("YYYY-MM-DD")}
          isView={true}
          time={moment().format("HH:mm")}
          isDrawerOpen={isOpenDrawer}
          isNew={false}
        />
      </div>
    </div>
  );
};
export default ReservationFloorViewComponent;
