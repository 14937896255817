import React from "react";
import down from "../../../assets/down-white.png";
import "./NumberInputStyles.scss";
interface CoversInputProps {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  onChange: any;
}
const CoversInput: React.FC<CoversInputProps> = ({
  value,
  setValue,
  onChange,
}) => {
  return (
    <div className='CoversInput'>
      <div className='numb-cont'>
        <input
          type='number'
          className='number-in'
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            onChange();
          }}
          min={1}
        />
      </div>
      <div className='arrow-cont-in'>
        <div
          className='cont'
          onClick={() => {
            setValue((value) => (Number(value) + 1).toString());
          }}
        >
          <img alt='' src={down} className='image' />
        </div>
        <div
          className='cont-2'
          onClick={() => {
            if (Number(value) !== 1) {
              setValue((value) => (Number(value) - 1).toString());
            }
          }}
        >
          <img alt='' src={down} className='image' />
        </div>
      </div>
    </div>
  );
};
export default CoversInput;
