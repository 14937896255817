import React, { useState, useEffect, useRef } from "react";
import "./TopBarStyles.scss";
import back from "../../../assets/arrow.png";
import search from "../../../assets/search.png";
import setting from "../../../assets/setting.png";
import bell from "../../../assets/bell.png";
import logout from "../../../assets/logout.png";
import DatePicker from "../datePicker/DatePicker";
import Dropdown from "../dropdown/Dropdown";
import { useNavigate } from "react-router-dom";
import BlockDropdown from "../dropdown/BlockDropdown";

interface TopBarProps {
  selectedDate: any;
  setSelectedDate: React.Dispatch<React.SetStateAction<any>>;
  title: string;
  details?: boolean;
  isOpenDrawer: boolean;
  isBack?: boolean;
  onBackClick?: () => void;
  isLayout?: boolean;
  blocked?: boolean;
}
const TopBar: React.FC<TopBarProps> = ({
  selectedDate,
  setSelectedDate,
  title,
  details,
  isOpenDrawer,
  isBack,
  onBackClick,
  isLayout,
  blocked,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  let user = localStorage.getItem("userDetails")
    ? JSON.parse(localStorage.getItem("userDetails") || "{}").UserData
        ?.FirstName
    : "";
  const [width, setWidth] = useState(window.innerWidth - 194);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth - 194);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  const ICONS = [
    {
      img: search,
    },
    {
      img: setting,
    },
    {
      img: bell,
    },
  ];
  const options = JSON.parse(localStorage.getItem("locations") || "[]");
  const handleDropdownChange = (value: string) => {
    console.log("Selected value:", value);
  };
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  const userProfile = () => {
    return (
      <div className='user-dropdown' ref={dropdownRef}>
        <div
          className='profile-icon'
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {user.charAt(0)}
        </div>
        {isOpen && (
          <div className='dropdown-content' onClick={handleLogout}>
            <img alt='' src={logout} />
            Log Out
          </div>
        )}
      </div>
    );
  };
  return (
    <div className='TopBar' style={{ width: isOpenDrawer ? width : "" }}>
      <div
        className='left-container'
        style={{ marginLeft: isBack ? "0%" : isLayout ? "2%" : "" }}
      >
        {isBack && (
          <img
            alt=''
            src={back}
            onClick={() => {
              if (onBackClick) {
                onBackClick();
              }
            }}
          />
        )}
        {title}
      </div>
      <div className='mid-container'>
        {details && (
          <DatePicker
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        )}
      </div>

      <div
        className='right-container'
        style={{ width: isBack || isLayout ? "35%" : "" }}
      >
        <Dropdown options={options} onChange={handleDropdownChange} />
        {!blocked && <BlockDropdown />}
        {ICONS.map((icon, index) => {
          return (
            <div key={index} className='icon-cont'>
              <img alt='' src={icon.img} />
            </div>
          );
        })}
        {userProfile()}
      </div>
    </div>
  );
};
export default TopBar;
