import React from "react";
import "./SearchInputStyles.scss";
import search from "../../../assets/search.png";

interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({
  value,
  onChange,
  placeholder,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className='SearchInput'>
      <img alt='' src={search} className='search' />
      <input
        type='text'
        value={value}
        onChange={handleChange}
        placeholder={placeholder ? placeholder : "Search Name"}
        className='search-input'
      />
    </div>
  );
};

export default SearchInput;
