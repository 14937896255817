import React from "react";
import minus from "../../../assets/minus.svg";
import add from "../../../assets/increase.svg";

import "./IncreaseButtonStyles.scss";
interface IncreaseButtonProps {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  min: number;
}
const IncreaseButton: React.FC<IncreaseButtonProps> = ({
  value,
  setValue,
  min,
}) => {
  const increase = () => {
    setValue(value + 1);
  };
  const decrease = () => {
    if (value !== min) {
      setValue(value - 1);
    }
  };
  return (
    <div className='IncreaseButton'>
      <div className='butn' onClick={decrease}>
        <img alt='' src={minus} />
      </div>
      <div className='number'>{value}</div>
      <div className='butn' onClick={increase}>
        <img alt='' src={add} />
      </div>
    </div>
  );
};
export default IncreaseButton;
