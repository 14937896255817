import React from "react";
import "./ModalStyles.scss";
import img from "../../../assets/warn.svg";

interface WarningModalProps {
  isOpen: boolean;
  onClose: () => void;
  onOk: () => void;
  header: string;
  content: string;
  isLoading?: boolean;
  secondary: boolean;
  primaryText: string;
  secondaryText?: string;
}

const WarningModal: React.FC<WarningModalProps> = ({
  isOpen,
  onClose,
  onOk,
  header,
  content,
  isLoading,
  secondary,
  primaryText,
  secondaryText,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className='modal-overlay'>
      <div className='modal-content' onClick={(e) => e.stopPropagation()}>
        <div className='header'>
          <img alt='' src={img} className='error' />
          {header}
        </div>
        <div className='content-2'>{content}</div>
        <div className='btn-container'>
          {secondary && (
            <div className='close' onClick={onClose}>
              {secondaryText || "Discard"}
            </div>
          )}
          <div className='ok-warning' onClick={onOk}>
            {isLoading ? <div className='loading-ic' /> : primaryText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningModal;
