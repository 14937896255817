import axios from "axios";
import globalValues from "../../globalValues";
import { generateToken } from "../../token/requests/Token";
export const addFloor = async (floor: any) => {
  try {
    const token = await generateToken();
    if (token) {
      return new Promise(async (resolve, reject) => {
        let headers = {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        };
        let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/floor`;
        await axios({
          method: "POST",
          url: url,
          headers,
          data: floor,
        })
          .then((response) => {
            if (response.data.Result !== null) {
              resolve(response.data.Result);
            } else {
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  } catch (error) {
    console.error("Error In adding floor Data", error);
  }
};
export const updateFloorName = async (floor: any) => {
  try {
    const token = await generateToken();
    if (token) {
      return new Promise(async (resolve, reject) => {
        let headers = {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        };
        let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/floor`;
        await axios({
          method: "PUT",
          url: url,
          headers,
          data: floor,
        })
          .then((response) => {
            if (response.data.Result !== null) {
              resolve(response.data.Result);
            } else {
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  } catch (error) {
    console.error("Error In updating floor Name", error);
  }
};
export const deleteFloor = async (FloorID: string) => {
  try {
    const token = await generateToken();
    if (token) {
      return new Promise(async (resolve, reject) => {
        let headers = {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        };
        let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/floor/${FloorID}`;
        await axios({
          method: "DELETE",
          url: url,
          headers,
        })
          .then((response) => {
            if (response.data.Result !== null) {
              resolve(response.data.Result);
            } else {
              resolve([]);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  } catch (error) {
    console.error("Error In delete floor", error);
  }
};
export const addTableToFloor = async (table: any) => {
  try {
    const token = await generateToken();
    if (token) {
      return new Promise(async (resolve, reject) => {
        let headers = {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        };
        let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/table`;
        await axios({
          method: "POST",
          url: url,
          headers,
          data: table,
        })
          .then((response) => {
            if (response.data.Result !== null) {
              resolve(response.data.Result);
            } else {
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  } catch (error) {
    console.error("Error In adding table to floor", error);
  }
};
export const updateTableRecord = async (table: any) => {
  try {
    const token = await generateToken();
    if (token) {
      return new Promise(async (resolve, reject) => {
        let headers = {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        };
        let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/table`;
        await axios({
          method: "POST",
          url: url,
          headers,
          data: table,
        })
          .then((response) => {
            if (response.data.Result !== null) {
              resolve(response.data.Result);
            } else {
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  } catch (error) {
    console.error("Error In adding table to floor", error);
  }
};
export const deleteTableFromFloor = async (id: string) => {
  try {
    const token = await generateToken();
    if (token) {
      return new Promise(async (resolve, reject) => {
        let headers = {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        };
        let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/table/${id}`;
        await axios({
          method: "DELETE",
          url: url,
          headers,
        })
          .then((response) => {
            if (response.data.Result !== null) {
              resolve(response.data.Result);
            } else {
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  } catch (error) {
    console.error("Error In adding table to floor", error);
  }
};
export const bulkInsert = async (floors: any) => {
  try {
    const token = await generateToken();
    if (token) {
      return new Promise(async (resolve, reject) => {
        let headers = {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        };
        let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/table/bulk-upsert`;
        await axios({
          method: "POST",
          url: url,
          headers,
          data: floors,
        })
          .then((response) => {
            if (response.data.Result !== null) {
              resolve(response.data.Result);
            } else {
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  } catch (error) {
    console.error("Error In adding table to floor", error);
  }
};
