import React, { useState, useEffect, useRef } from "react";
import "./ReservationStyles.scss";
import TopBar from "../../../components/ReservationComponents/topBar/TopBar";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../application/store";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/button/Button";
import { getFloorManagementDetails } from "../../../../infra/apis/bookings/requests/Booking";
import {
  setDefaultFloorLayoutData,
  setFloorLayoutData,
  setIslocationSwitching,
} from "../../../../application/reducer/bookingSlice";
import FloorConfigLayout from "../../../components/ReservationComponents/floorLayout/FloorConfigLayout";
import { v4 as uuidv4 } from "uuid";
import DeleteModal from "../../../components/ReservationComponents/errorModal/DeleteModal";
import RenameModal from "../../../components/ReservationComponents/errorModal/RenameModal";
import TableLayout from "../../../components/ReservationComponents/floorLayout/Table/TableLayout";
import { useDrop } from "react-dnd";
import RoomLayout from "./RoomLayout";
import WarningModal from "../../../components/ReservationComponents/errorModal/WarningModal";
import table from "../../../assets/fl-table.svg";
import cvr from "../../../assets/fl-cvr.svg";
import onlinestatus from "../../../assets/fl-status.svg";
import {
  addFloor,
  bulkInsert,
  deleteFloor,
  updateFloorName,
} from "../../../../infra/apis/bookings/requests/FloorLayout";
import SavingModal from "../../../components/ReservationComponents/errorModal/SavingModal";
import plus from "../../../assets/plus.svg";
import _ from "lodash";
import globalValues from "../../../../infra/apis/globalValues";
import moment from "moment";
import SuccessModal from "../../../components/ReservationComponents/errorModal/SuccessModal";
import arrow from "../../../assets/Polygon.svg";

const Layout: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<any>(
    localStorage.getItem("selectedDate")
      ? JSON.parse(
          localStorage.getItem("selectedDate") || new Date().toDateString()
        )
      : new Date()
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedFloor, setSelectedFloor] = useState<any>(null);
  const [parentHeight, setParentHeight] = useState(540);
  const [parentWidth, setParentWidth] = useState(1000);

  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [isRenameOpen, setIsRenameOpen] = useState<boolean>(false);
  const [renameLoading, setRenameLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [isWarningOpen, setIsWarningOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSaveChangeOpen, setIsSaveChangesOpen] = useState<boolean>(false);
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [selectingFloor, setSelectingFloor] = useState<any>(null);

  const [isSaveBackChangeOpen, setIsSaveBackChangesOpen] =
    useState<boolean>(false);
  const [isSaveBackLoading, setIsSaveBackLoading] = useState<boolean>(false);
  const [isReservationWarningOpen, setIsReservationWarningOpen] =
    useState<boolean>(false);
  const [isRoomDeleteWarningOpen, setIsRoomDeleteWarningOpen] =
    useState<boolean>(false);
  const [width, setWidth] = useState<any>("");
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrowCont, setShowRightArrowCont] = useState(true);
  const locationId = useSelector(
    (state: RootState) => state.booking.locationId
  );
  const floorLayoutData = useSelector(
    (state: RootState) => state.booking.floorLayoutData
  );
  const defaultFloorLayoutData = useSelector(
    (state: RootState) => state.booking.defaultFloorLayoutData
  );

  const organizationDetails = useSelector(
    (state: RootState) => state.booking.organizationDetails
  );
  const islocationSwitching = useSelector(
    (state: RootState) => state.booking.islocationSwitching
  );
  const parentRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, ref] = useDrop({
    accept: "Room",
    drop: (draggedItem: any, monitor) => {
      const containerRect = containerRef?.current?.getBoundingClientRect();
      const offset = monitor.getClientOffset();
      if (!offset || !containerRect) return;
      let range = Math.ceil((offset.x - containerRect.left) / 180) - 1;
      const updatedItems = [...floorLayoutData];
      const draggedItemContent = updatedItems[draggedItem.index];
      updatedItems.splice(draggedItem.index, 1);
      updatedItems.splice(range, 0, draggedItemContent);
      setSelectedFloor(draggedItem.floor);
      dispatch(setFloorLayoutData(updatedItems));
    },
  });
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.screen.width);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    let userDetails = localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails") || "")
      : "";
    if (userDetails === "" || userDetails === null) {
      navigate("/");
    } else {
      getData();
    }
  }, []);
  useEffect(() => {
    const parent = parentRef.current;
    if (selectedFloor?.Tables?.length > 0) {
      if (parent) {
        const isOverflowingHeight = parent.scrollHeight > parent.clientHeight;
        if (isOverflowingHeight) {
          setParentHeight((prevHeight) => prevHeight + 540);
        }
      }
    } else {
      setParentHeight(540);
    }
  }, [selectedFloor, parentHeight]);
  useEffect(() => {
    const parent = parentRef.current;
    if (selectedFloor?.Tables?.length > 0) {
      if (parent) {
        const isOverflowingHeight = parent.scrollWidth > parent.clientWidth;
        if (isOverflowingHeight) {
          setParentWidth((prevWidth) => prevWidth + 500);
        }
      }
    } else {
      setParentWidth(1000);
    }
  }, [selectedFloor, parentWidth]);
  useEffect(() => {
    const scrollContainer = containerRef.current;
    if (scrollContainer) {
      setShowRightArrow(
        scrollContainer.scrollWidth > scrollContainer.clientWidth
      );
    }
  }, [floorLayoutData, loading]);
  useEffect(() => {
    const scrollContainer = containerRef.current;
    if (scrollContainer) {
      setShowRightArrowCont(
        scrollContainer.scrollWidth > scrollContainer.clientWidth
      );
      const handleScroll = () => {
        const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;
        setShowLeftArrow(scrollLeft > 0);
        setShowRightArrowCont(scrollLeft + clientWidth < scrollWidth);
      };

      scrollContainer.addEventListener("scroll", handleScroll);
      return () => scrollContainer.removeEventListener("scroll", handleScroll);
    }
  }, [selectedFloor]);
  const getData = async () => {
    let isloaded = await getFloorData();
    if (isloaded) {
      setLoading(false);
    }
  };
  const handleSelectedFloor = (sortedFloor: any[]) => {
    const queryString = window.location.search;
    if (queryString) {
      const urlParams = new URLSearchParams(queryString);
      const floorId = urlParams.get("floor");
      let floor = sortedFloor.find((floor: any) => floor.FloorID === floorId);
      if (floor) {
        return floor;
      } else {
        return sortedFloor[0];
      }
    } else {
      return sortedFloor[0];
    }
  };
  const getFloorData = async () => {
    return new Promise((resolve) => {
      getFloorManagementDetails().then(async (response: any) => {
        if (response.length !== 0) {
          const sortedFloor: any = [...response].sort(
            (a, b) => a.Priority - b.Priority
          );
          dispatch(setFloorLayoutData(sortedFloor));
          dispatch(setDefaultFloorLayoutData(sortedFloor));
          let floor = handleSelectedFloor(sortedFloor);
          setSelectedFloor(floor);
        } else {
          dispatch(setFloorLayoutData([]));
          dispatch(setDefaultFloorLayoutData([]));
        }
        dispatch(setIslocationSwitching(false));
        resolve(true);
      });
    });
  };
  const createDummyRoom = () => {
    let room = {
      CloudLocationID: locationId,
      Description: "",
      DisplayName: `Main Room`,
      FloorID: uuidv4(),
      IsDeleted: false,
      Name: `Main Room`,
      OrganizationID: organizationDetails.ID,
      Priority: 1,
      isNew: true,
    };
    let Tables = [
      {
        CloudLocationID: locationId,
        Covers: {
          Maximum: 4,
          Minimum: 1,
        },
        Dimensions: {
          Angle: 0,
          Height: 95,
          Shape: "RECTANGLE",
          TableName: "1",
          Width: 100,
          XValue: 0,
          YValue: 0,
        },
        IsOnline: true,
        OrganizationID: organizationDetails.ID,
        Priority: 1,
        TableName: "1",
        ID: uuidv4().replace(/-/g, "").substring(0, 24),
        Floor: {
          CloudRefNo: room.FloorID,
        },
        IsActive: globalValues.environment.ENV !== "production" ? 1 : 0,
        Reservations: [],
      },
      {
        CloudLocationID: locationId,
        Covers: {
          Maximum: 4,
          Minimum: 1,
        },
        Dimensions: {
          Angle: 0,
          Height: 95,
          Shape: "CIRCLE",
          TableName: "1",
          Width: 100,
          XValue: 100,
          YValue: 0,
        },
        IsOnline: true,
        OrganizationID: organizationDetails.ID,
        Priority: 1,
        TableName: "2",
        ID: uuidv4().replace(/-/g, "").substring(0, 24),
        Floor: {
          CloudRefNo: room.FloorID,
        },
        IsActive: globalValues.environment.ENV !== "production" ? 1 : 0,
        Reservations: [],
      },
    ];
    let newRoom = { ...room, Tables: Tables };
    setSelectedFloor(newRoom);
    dispatch(setFloorLayoutData([...floorLayoutData, newRoom]));
    // let data = {
    //   Floors: floor.map(({ Tables, ...floor }) => floor),
    //   CloudLocationID: locationId,
    //   Tables: floor.flatMap((floor) => floor.Tables),
    //   Date: moment().format("YYYY-MM-DD"),
    // };
    // setIsSaving(true);
    // bulkInsert(data).then((response: any) => {
    //   const sortedFloor: any = [...response].sort(
    //     (a, b) => a.Priority - b.Priority
    //   );
    //   dispatch(setFloorLayoutData(sortedFloor));
    //   setSelectedFloor(response[0]);
    //   setIsSaving(false);
    //   setIsSuccess(true);
    // });
  };
  const addNewRoom = () => {
    let newRoom = {
      CloudLocationID: locationId,
      Description: "",
      DisplayName: `Main Room - ${floorLayoutData.length + 1}`,
      IsDeleted: false,
      Name: `Main Room - ${floorLayoutData.length + 1}`,
      OrganizationID: organizationDetails.ID,
      Priority: floorLayoutData.length + 1,
      Tables: [],
      FloorID: uuidv4(),
      isNew: true,
    };
    setSelectedFloor(newRoom);
    dispatch(setFloorLayoutData([...floorLayoutData, newRoom]));
  };
  const updateRoomName = () => {
    let newRoom = {
      ...selectedFloor,
      DisplayName: name,
      Name: name,
    };
    setRenameLoading(true);
    if (selectedFloor.isNew) {
      setSelectedFloor(newRoom);
      const floorIndex = floorLayoutData.findIndex(
        (item) => item.FloorID === selectedFloor?.FloorID
      );
      const updatedList = [
        ...floorLayoutData.slice(0, floorIndex),
        newRoom,
        ...floorLayoutData.slice(floorIndex + 1),
      ];
      dispatch(setFloorLayoutData(updatedList));
      setRenameLoading(false);
      setIsRenameOpen(false);
    } else {
      updateFloorName(newRoom)
        .then((response) => {
          setRenameLoading(false);
          setIsRenameOpen(false);
          window.location.reload();
        })
        .catch(() => {
          setRenameLoading(false);
        });
    }
  };

  const duplicateRoom = () => {
    let duplicateRoomObj = {
      CloudLocationID: locationId,
      Description: selectedFloor.Description,
      DisplayName: `${selectedFloor.DisplayName} - ${
        floorLayoutData.length + 1
      }`,
      FloorID: uuidv4(),
      IsDeleted: selectedFloor.IsDeleted,
      Name: `${selectedFloor.Name} - ${floorLayoutData.length + 1}`,
      OrganizationID: organizationDetails.ID,
      Priority: floorLayoutData.length + 1,
      isNew: true,
    };
    let newTables =
      selectedFloor.Tables.length > 0
        ? selectedFloor.Tables.map((table: any, index: number) => {
            return {
              CloudLocationID: locationId,
              Covers: {
                Maximum: table.Covers.Maximum,
                Minimum: table.Covers.Minimum,
              },
              Dimensions: {
                ...table.Dimensions,
              },
              IsOnline: true,
              OrganizationID: organizationDetails.ID,
              Priority: 1,
              TableName: `${table.TableName} (${index + 1})`,
              isNew: true,
              ID: uuidv4().replace(/-/g, "").substring(0, 24),
              Floor: {
                CloudRefNo: duplicateRoomObj.FloorID,
              },
              IsActive: globalValues.environment.ENV !== "production" ? 1 : 0,
              Reservations: [],
            };
          })
        : [];
    let newRoom = { ...duplicateRoomObj, Tables: newTables };
    setSelectedFloor(newRoom);
    dispatch(setFloorLayoutData([...floorLayoutData, newRoom]));
  };
  const getIsActiveTables = () => {
    let isActive = globalValues.environment.ENV !== "production" ? 1 : 0;
    let tables = selectedFloor?.Tables
      ? selectedFloor?.Tables.filter(
          (table: any) => table.IsActive === isActive
        )
      : [];
    return tables;
  };
  const countTableDetails = (): any => {
    return getIsActiveTables()?.reduce(
      (acc: any, table: any) => {
        acc.tableCount = getIsActiveTables().length;
        acc.totalMinCovers += table.Covers.Minimum;
        acc.totalMaxCovers += table.Covers.Maximum;
        if (table.IsOnline) {
          acc.onlineStatusMinCount += table.Covers.Minimum;
          acc.onlineStatusMaxCount += table.Covers.Maximum;
        }
        return acc;
      },
      {
        totalMinCovers: 0,
        totalMaxCovers: 0,
        onlineStatusMinCount: 0,
        onlineStatusMaxCount: 0,
        tableCount: 0,
      }
    );
  };
  const deleteRoom = () => {
    setDeleteLoading(true);
    if (selectedFloor.FloorID && selectedFloor.isNew) {
      let updatedRooms = floorLayoutData.filter(
        (floor) => floor.FloorID !== selectedFloor.FloorID
      );
      setIsDeleteOpen(false);
      dispatch(setFloorLayoutData(updatedRooms));
      setDeleteLoading(false);
      setSelectedFloor(updatedRooms[0]);
    } else {
      let id = selectedFloor && selectedFloor.FloorID;

      deleteFloor(id)
        .then((response) => {
          setDeleteLoading(false);
          setIsDeleteOpen(false);
          window.location.reload();
        })
        .catch(() => {
          setDeleteLoading(false);
        });
    }
  };
  const saveRoom = (from: string) => {
    const floorIndex = floorLayoutData.findIndex(
      (item) => item.FloorID === selectedFloor?.FloorID
    );
    if (floorIndex !== -1) {
      const updatedList = [
        ...floorLayoutData.slice(0, floorIndex),
        selectedFloor,
        ...floorLayoutData.slice(floorIndex + 1),
      ];
      const updatedPriority = updatedList.map((floor: any, index: number) => ({
        ...floor,
        Priority: index + 1,
      }));
      let data = {
        Floors: updatedPriority.map(({ Tables, ...floor }) => floor),
        CloudLocationID: locationId,
        Tables: updatedPriority.flatMap((floor) => floor.Tables),
        Date: moment().format("YYYY-MM-DD"),
      };
      if (from === "SAVE") {
        setIsSaving(true);
      } else if (from === "BACK") {
        setIsSaveBackLoading(true);
      } else {
        setIsSaveLoading(true);
      }
      bulkInsert(data)
        .then((response: any) => {
          const sortedFloor: any = [...response].sort(
            (a, b) => a.Priority - b.Priority
          );
          dispatch(setFloorLayoutData(sortedFloor));
          dispatch(setDefaultFloorLayoutData(sortedFloor));

          let obj = response.find(
            (floor: any) => floor.FloorID === selectedFloor.FloorID
          );
          setSelectedFloor(obj);
          if (from === "SAVE") {
            setIsSaving(false);
            setIsSuccess(true);
          } else if (from === "BACK") {
            setIsSaveBackLoading(false);
            setIsSaveBackChangesOpen(false);
            navigate("/v1/booking/floor-view");
          } else {
            setIsSaveLoading(false);
            setIsSaveChangesOpen(false);
          }
        })
        .catch(() => {
          setIsSaveLoading(false);
          setIsSaving(false);
          setIsSaveChangesOpen(false);
          setIsSaveBackLoading(false);
          setIsSaveBackChangesOpen(false);
        });
    }
  };
  const clearChanges = () => {
    if (selectedFloor.isNew) {
      const filteredFloors = floorLayoutData.filter(
        (floor) => floor.FloorID !== selectedFloor.FloorID
      );
      setSelectedFloor(filteredFloors[0]);
      dispatch(setFloorLayoutData(filteredFloors));
    } else {
      const findFloor = floorLayoutData.find(
        (floor) => floor.FloorID === selectedFloor.FloorID
      );
      setSelectedFloor(findFloor);
    }
  };

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -180,
        behavior: "smooth",
      });
    }
  };
  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 180,
        behavior: "smooth",
      });
    }
  };
  return (
    <div className='Reservation'>
      {islocationSwitching ? (
        <div className='empty'>
          <div className='loader' />
        </div>
      ) : width > 900 ? (
        <>
          <div className='reservation-content'>
            <TopBar
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              title='Layout'
              isOpenDrawer={false}
              isBack={floorLayoutData.length > 0 ? true : false}
              onBackClick={() => {
                const findFloor = defaultFloorLayoutData.find(
                  (floor) => floor.FloorID === selectedFloor.FloorID
                );
                if (
                  _.isEqual(selectedFloor?.Tables, findFloor?.Tables) &&
                  !selectedFloor.isNew
                ) {
                  navigate(
                    `/v1/booking/floor-view?floor=${selectedFloor.FloorID}`
                  );
                } else {
                  setIsSaveBackChangesOpen(true);
                }
              }}
              isLayout
            />
            {loading ? (
              <div className='loader' />
            ) : (
              <div className='layout-view'>
                <div className='left-view'>
                  <div className='fixed-view'>
                    <div className='header-container'>
                      <div className='content content-selected '>Tables</div>
                      <div className='content'></div>
                    </div>
                  </div>
                  <div className='table-view'>
                    <div className='header'>Table Options</div>
                    <div className='sub'>Drag and drop your tables</div>
                    <div>
                      <div className='table-layout'>
                        <TableLayout
                          height={95}
                          width={100}
                          chairCount={2}
                          type='RECTANGLE'
                          name='1'
                          isLocked={false}
                          isView={true}
                          isEdit={false}
                          isNew={true}
                          maxCovers={4}
                        />
                        <TableLayout
                          height={95}
                          width={100}
                          chairCount={2}
                          type='CIRCLE'
                          name='2'
                          isLocked={false}
                          isView={true}
                          isEdit={false}
                          isNew={true}
                          maxCovers={4}
                        />
                      </div>
                      <div className='table-layout'>
                        <TableLayout
                          height={95}
                          width={190}
                          chairCount={4}
                          type='RECTANGLE'
                          name='2'
                          isLocked={false}
                          isView={true}
                          isEdit={false}
                          isNew={true}
                          maxCovers={6}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='right-view'>
                  <div className='FloorView'>
                    <div className='header-container'>
                      <div
                        className='rooms'
                        ref={(node) => {
                          ref(node);
                          if (containerRef) {
                            containerRef.current = node;
                          }
                        }}
                        style={{ width: width <= 1366 ? "50%" : "" }}
                      >
                        {floorLayoutData.map((floor: any, index: number) => {
                          return (
                            <RoomLayout
                              floor={floor}
                              selectedFloor={selectedFloor}
                              setSelectedFloor={setSelectedFloor}
                              setName={setName}
                              setIsDeleteOpen={setIsDeleteOpen}
                              setIsRenameOpen={setIsRenameOpen}
                              duplicateRoom={duplicateRoom}
                              index={index}
                              setIsSaveChangesOpen={setIsSaveChangesOpen}
                              setIsRoomDeleteWarningOpen={
                                setIsRoomDeleteWarningOpen
                              }
                              setSelectingFloor={setSelectingFloor}
                            />
                          );
                        })}
                      </div>
                      {showRightArrow ? (
                        <div className='arrow-container'>
                          <div
                            onClick={() => {
                              if (showLeftArrow) {
                                scrollLeft();
                              }
                            }}
                            className='arrow-left'
                          >
                            <img
                              alt=''
                              src={arrow}
                              className={
                                showLeftArrow ? "img-arr " : "img-arr-filter"
                              }
                            />
                          </div>
                          <div
                            onClick={() => {
                              if (showRightArrowCont) {
                                scrollRight();
                              }
                            }}
                            className='arrow-right'
                          >
                            <img
                              alt=''
                              src={arrow}
                              className={
                                showRightArrowCont
                                  ? "img-arr "
                                  : "img-arr-filter"
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <div className='empty-arrow-cont' />
                      )}
                      {floorLayoutData.length !== 0 && (
                        <div className='btn-container'>
                          <div
                            className='sec-btn '
                            onClick={() => {
                              addNewRoom();
                            }}
                          >
                            <img alt='' src={plus} />
                            Add Room
                          </div>
                          <Button
                            text='Save Room'
                            auth
                            height='34px'
                            width='120px'
                            padding='0'
                            loading={isSaving}
                            onClick={() => {
                              saveRoom("SAVE");
                            }}
                          />
                        </div>
                      )}
                    </div>

                    <div
                      className='floor-l'
                      ref={parentRef}
                      style={{
                        height: `${parentHeight}px`,
                        width: `${parentWidth}px`,
                      }}
                    >
                      <FloorConfigLayout
                        items={
                          selectedFloor
                            ? selectedFloor?.Tables !== null
                              ? getIsActiveTables()
                              : []
                            : []
                        }
                        setSelectedFloor={setSelectedFloor}
                        selectedFloor={selectedFloor}
                        addRoom={createDummyRoom}
                        setIsReservationWarningOpen={
                          setIsReservationWarningOpen
                        }
                      />
                    </div>
                  </div>
                  {floorLayoutData?.length > 0 && (
                    <div className='floating'>
                      <div className='item'>
                        <img alt='' src={table} />
                        {(selectedFloor?.Tables &&
                          countTableDetails().tableCount) ||
                          0}{" "}
                        Tables
                      </div>
                      <div className='item'>
                        <img alt='' src={cvr} />
                        {(selectedFloor?.Tables &&
                          countTableDetails().totalMinCovers) ||
                          0}{" "}
                        Min. Covers
                      </div>
                      <div className='item'>
                        <img alt='' src={cvr} />
                        {(selectedFloor?.Tables &&
                          countTableDetails().totalMaxCovers) ||
                          0}{" "}
                        Max. Covers
                      </div>
                      <div className='item'>
                        <img alt='' src={onlinestatus} />
                        {(selectedFloor?.Tables &&
                          countTableDetails().onlineStatusMinCount) ||
                          0}{" "}
                        -{" "}
                        {(selectedFloor?.Tables &&
                          countTableDetails().onlineStatusMaxCount) ||
                          0}{" "}
                        Online Capacity
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className='landscape'>
          Please rotate your device to landscape mode
        </div>
      )}
      <DeleteModal
        isOpen={isDeleteOpen}
        onClose={() => {
          setIsDeleteOpen(false);
        }}
        onDelete={() => {
          deleteRoom();
        }}
        content='Do you want to delete this room? '
        isLoading={deleteLoading}
      />
      {isRenameOpen && (
        <RenameModal
          isOpen={isRenameOpen}
          onClose={() => {
            setIsRenameOpen(false);
          }}
          onChange={() => {
            updateRoomName();
          }}
          isLoading={renameLoading}
          name={name}
          setName={setName}
          selectedFloor={selectedFloor}
        />
      )}
      <WarningModal
        isOpen={isWarningOpen}
        onClose={() => {
          setIsWarningOpen(false);
        }}
        onOk={() => {
          setIsWarningOpen(false);
        }}
        content='You have not added any tables yet. lets start by editing the floor layout'
        header='Warning'
        secondary={false}
        primaryText='OK'
      />
      <SavingModal isOpen={isSaving} />
      <WarningModal
        isOpen={isSaveChangeOpen}
        onClose={() => {
          setIsSaveLoading(false);
          setIsSaveChangesOpen(false);
          clearChanges();
          setSelectedFloor(selectingFloor);
        }}
        onOk={() => saveRoom("MODAL")}
        content='You have unsaved changes. Do you want to continue?'
        isLoading={isSaveLoading}
        header='Warning'
        secondary={true}
        primaryText='Save'
      />
      <WarningModal
        isOpen={isSaveBackChangeOpen}
        onClose={() => {
          setIsSaveBackLoading(false);
          setIsSaveBackChangesOpen(false);
          clearChanges();
          navigate(`/v1/booking/floor-view?floor=${selectedFloor.FloorID} `);
        }}
        onOk={() => saveRoom("BACK")}
        content='You have unsaved changes. Do you want to continue?'
        isLoading={isSaveBackLoading}
        header='Warning'
        secondary={true}
        primaryText='Save'
      />
      <WarningModal
        isOpen={isReservationWarningOpen}
        onClose={() => {
          setIsReservationWarningOpen(false);
        }}
        onOk={() => {
          setIsReservationWarningOpen(false);
        }}
        content='This table has reservations. Please move them to another table before deletion!'
        header='Warning'
        secondary={false}
        primaryText='OK'
      />
      <WarningModal
        isOpen={isRoomDeleteWarningOpen}
        onClose={() => {
          setIsRoomDeleteWarningOpen(false);
        }}
        onOk={() => {
          setIsRoomDeleteWarningOpen(false);
        }}
        content='You can’t delete a room with reservations .'
        header='Warning'
        secondary={false}
        primaryText='OK'
      />
      <SuccessModal
        isOpen={isSuccess}
        onClose={() => {
          setIsSuccess(false);
        }}
        onOk={() => {
          setIsSuccess(false);
        }}
        header='Successful!'
        content='Room saved successfully'
      />
    </div>
  );
};
export default Layout;
