import React, { useEffect } from "react";
import LogoContainer from "../../components/logocontainer/LogoContainer";
import BaseHeader from "../../components/baseheader/BaseHeader";
import check from "../../assets/check.png";
import "./BookingStyles.scss";
import QRComponent from "../../components/qr/QRComponent";
import { useLocation, useNavigate } from "react-router-dom";

const BookingPageThree: React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    let id = localStorage.getItem("wrlId");
    if (id === null) {
      navigate("/");
    }
  }, []);
  return (
    <div className='page'>
      <div className='border' />
      <div className='BookingPageThree'>
        <div className='left-container'>
          {/* <div className='logo-witmeg'>
            <img alt='' src={witmeg} />
          </div> */}
          <LogoContainer />
        </div>
        <div className='right-container'>
          <div className='form-container'>
            <BaseHeader />
            <div className='check-container'>
              <div className='check-icon-container'>
                <img alt='' src={check} className='check' />
              </div>
            </div>
            <QRComponent
              value={state?.ReservationID ? state?.ReservationID : ""}
            />
            <div className='info'>
              <div className='header'>Reservation confirmed!</div>
              <div className='info-content '>{state?.Date}</div>
              <div className='info-content'>{state?.PartySize} guests</div>
              <div className='info-content'>
                ID :<span>{state?.ReservationID}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BookingPageThree;
