import React from "react";
import "./CustomerModalStyles.scss";
import close from "../../../../assets/close.png";
interface CustomerModalProps {
  onClose: () => void;
  data: any[];
  setSelectedCustomer: React.Dispatch<React.SetStateAction<any>>;
}
const CustomerModal: React.FC<CustomerModalProps> = ({
  onClose,
  data,
  setSelectedCustomer,
}) => {
  return (
    <div className='customer-modal-overlay' onClick={onClose}>
      <div
        className='customer-modal-content'
        onClick={(e) => e.stopPropagation()}
      >
        <div className='header'>
          <img alt='' src={close} onClick={onClose} />
        </div>
        <div className='content'>
          {data.length > 0 ? (
            data.map((customer: any) => {
              return (
                <div
                  className='customer'
                  onClick={() => {
                    setSelectedCustomer(customer);
                    onClose();
                  }}
                >
                  <div className='name'>
                    {customer.Personal.FirstName} {customer.Personal.LastName}
                  </div>
                  <div className='email'>{customer.Email}</div>
                </div>
              );
            })
          ) : (
            <div className='empty-customer'>No customers exist</div>
          )}
        </div>
      </div>
    </div>
  );
};
export default CustomerModal;
