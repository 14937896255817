import React from "react";
import "./NotesModalStyles.scss";
import close from "../../../../assets/close.png";
import TextArea from "../../../textarea/TextArea";

interface NotesModalProps {
  isOpen: boolean;
  onClose: () => void;
  notes: string;
  setNotes: React.Dispatch<React.SetStateAction<string>>;
  isEdit: boolean;
}

const NotesModal: React.FC<NotesModalProps> = ({
  isOpen,
  onClose,
  notes,
  setNotes,
  isEdit,
}) => {
  if (!isOpen) {
    return null;
  }
  return (
    <div className='notes-modal-overlay' onClick={onClose}>
      <div
        className='notes-modal-content'
        onClick={(e) => e.stopPropagation()}
        style={{ left: isEdit ? "37.2%" : "", top: isEdit ? "2rem" : "" }}
      >
        <div className='header'>
          <img alt='' src={close} onClick={onClose} />
        </div>
        <div className='content'>
          <TextArea
            label='Add Notes'
            value={notes}
            onChange={(value) => {
              setNotes(value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NotesModal;
