import React from "react";
import "./ModalStyles.scss";
import img from "../../../assets/ok.svg";

interface SuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  onOk: () => void;
  header: string;
  content: string;
}

const SuccessModal: React.FC<SuccessModalProps> = ({
  isOpen,
  onClose,
  onOk,
  header,
  content,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className='modal-overlay' onClick={onClose}>
      <div className='modal-content' onClick={(e) => e.stopPropagation()}>
        <div className='header'>
          <img alt='' src={img} className='error' />
          {header}
        </div>
        <div className='content-2'>{content}</div>
        <div className='btn-container'>
          <div className='ok' onClick={onOk}>
            OK
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
