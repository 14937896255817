import React, { useState } from "react";
import "./ShiftViewStyles.scss";
import arrow from "../../../assets/arrow.png";
import table from "../../../assets/res.svg";
import tbl from "../../../assets/cover.svg";
import user from "../../../assets/people.svg";
import ppl from "../../../assets/ppl.svg";
import more from "../../../assets/more.png";

interface ShiftsFloorViewProps {
  shift: any;
  bookings: any[];
  unsortedBookings: any[];
  setSelectedTable: React.Dispatch<React.SetStateAction<any>>;
  setIsTableView: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFilter: string;
}
const ShiftsFloorView: React.FC<ShiftsFloorViewProps> = ({
  shift,
  bookings,
  unsortedBookings,
  setIsTableView,
  setSelectedTable,
  selectedFilter,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const counts = () => {
    let totalNumberOfPeople = 0;
    let assetAllocationLengths = 0;
    for (let i = 0; i < unsortedBookings.length; i++) {
      assetAllocationLengths += unsortedBookings[i].AssetAllocation.length;
      totalNumberOfPeople += unsortedBookings[i].BookingDetails.NumberOfPeople;
    }
    return { totalNumberOfPeople, assetAllocationLengths };
  };
  return (
    <div className='ShiftsFloorView'>
      <div
        className='shift-type'
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className='left-shift-view'>{shift}</div>
        <div className='right-shift-view '>
          {unsortedBookings && unsortedBookings.length > 0 ? (
            <>
              <div className='res-det'>
                <div className='table'>
                  <div className='tbl'>
                    <img alt='' src={table} />
                  </div>
                  {counts().assetAllocationLengths}
                </div>
                <div className='cover'>
                  <div className='cvr'>
                    <img alt='' src={ppl} />
                  </div>
                  {counts().totalNumberOfPeople}
                </div>
              </div>
              <div className='arrow-cont'>
                <img alt='' src={arrow} />
              </div>
            </>
          ) : (
            <div className='no-res'>No Reservations</div>
          )}
        </div>
      </div>
      {isOpen ? (
        bookings && bookings.length > 0 ? (
          <div className='shift-list'>
            {bookings.map((booking) => {
              return (
                <div
                  className='shift-container'
                  onClick={() => {
                    setIsTableView(true);
                    setSelectedTable(booking);
                  }}
                >
                  <div className='namecontainer'>
                    {booking.CustomerDetails.FirstName}{" "}
                    {booking.CustomerDetails.LastName}
                    <img alt='' src={more} />
                  </div>
                  <div className='detailscontainer'>
                    <div className='time'>
                      {booking.BookingDetails.BookingTime}
                    </div>
                    <div className='det'>
                      <div className='cont'>
                        <img alt='' src={user} />
                        {booking.BookingDetails.NumberOfPeople}
                      </div>
                      <div className='cont'>
                        <img alt='' src={tbl} className='tbl' />
                        {booking.AssetAllocation.length}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className='empty-container'>
            No {selectedFilter.toLowerCase()} bookings available
          </div>
        )
      ) : (
        ""
      )}
    </div>
  );
};
export default ShiftsFloorView;
