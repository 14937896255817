import React, { useState, useEffect, useRef } from "react";
import "./DropdownStyles.scss";
import arrow from "../../../assets/arrow.png";
import {
  setDefaultFloorLayoutData,
  setFloorLayoutData,
  setIslocationSwitching,
  setLocationId,
  setOrganizationalDetails,
} from "../../../../application/reducer/bookingSlice";
import { useDispatch } from "react-redux";
import { getLocations } from "../../../../application/utils";
import {
  getFloorManagementDetails,
  getOrganizationDetails,
} from "../../../../infra/apis/bookings/requests/Booking";
import { useNavigate } from "react-router-dom";

interface DropdownProps {
  options: any;
  onChange: (value: string) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ options, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(
    localStorage.getItem("locName")
      ? localStorage.getItem("locName")
      : options
      ? options[0]?.Name
      : ""
  );
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleToggle = () => setIsOpen(!isOpen);
  const handleSelect = async (option: any) => {
    dispatch(setIslocationSwitching(true));
    localStorage.setItem("wrlId", option.ID || "");
    localStorage.setItem("locName", option.Name);
    dispatch(setLocationId(option.ID));
    getOrganizationDetails(option.ID).then((orgnizationDetails) => {
      dispatch(setOrganizationalDetails(orgnizationDetails));
    });
    setSelectedOption(option.Name);
    onChange(option.Name);
    setIsOpen(false);
    const newUrl = window.location.pathname;
    window.history.replaceState({}, "", newUrl);
  };

  // Close the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  return (
    <div className='Dropdown' ref={dropdownRef}>
      <div className='cont' onClick={handleToggle}>
        <div className='dropdown-button'>{selectedOption}</div>
        <img alt='' src={arrow} />
      </div>

      {isOpen && (
        <div className='dropdown-content'>
          {Object.keys(getLocations(options)).map((orgName) => (
            <div key={orgName}>
              <div className='org'>{orgName}</div>
              {getLocations(options)[orgName].map(
                (location: any, index: number) => (
                  <div
                    key={index}
                    className='location'
                    onClick={() => {
                      handleSelect(location);
                      getFloorManagementDetails().then((response: any) => {
                        if (response.length === 0) {
                          dispatch(setFloorLayoutData([]));
                          dispatch(setDefaultFloorLayoutData([]));
                          navigate("/v1/booking/floor-layout");
                          dispatch(setIslocationSwitching(false));
                        } else {
                          window.location.reload();
                        }
                      });
                    }}
                  >
                    {location.Name}
                  </div>
                )
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
