import React, { useState } from "react";
import "./ShiftViewStyles.scss";
import arrow from "../../../assets/arrow.png";
import table from "../../../assets/res.svg";
import up from "../../../assets/up-arrow.png";
import user from "../../../assets/user.png";
import ppl from "../../../assets/ppl.svg";

interface ShiftsViewProps {
  shift: any;
  bookings: any[];
  unsortedBookings: any[];
  selectedFilter: string;
  setSelectedTable: React.Dispatch<React.SetStateAction<any>>;
  setIsTableView: React.Dispatch<React.SetStateAction<boolean>>;
}
const ShiftsView: React.FC<ShiftsViewProps> = ({
  shift,
  bookings,
  unsortedBookings,
  selectedFilter,
  setIsTableView,
  setSelectedTable,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isReversed, setIsReversed] = useState<boolean>(false);
  const counts = () => {
    let totalNumberOfPeople = 0;
    let totalTableLength = unsortedBookings.length;
    for (let i = 0; i < unsortedBookings.length; i++) {
      totalNumberOfPeople += unsortedBookings[i].BookingDetails.NumberOfPeople;
    }
    return { totalNumberOfPeople, totalTableLength };
  };

  const toggleArray = () => {
    let array = bookings;
    if (isReversed) {
      array = [...bookings].reverse();
    } else {
      array = bookings;
    }
    return array;
  };
  return (
    <div className='ShiftsView'>
      <div
        className='shift-type'
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className='left-shift-view'>{shift}</div>
        <div className='right-shift-view '>
          {unsortedBookings && unsortedBookings?.length > 0 ? (
            <>
              <div className='res-det'>
                <div className='table'>
                  <div className='tbl'>
                    <img alt='' src={table} />
                  </div>
                  {counts().totalTableLength}
                </div>
                <div className='cover'>
                  <div className='cvr'>
                    <img alt='' src={ppl} />
                  </div>
                  {counts().totalNumberOfPeople}
                </div>
              </div>
              <div className='arrow-cont'>
                <img alt='' src={arrow} />
              </div>
            </>
          ) : (
            <div className='no-res'>No Reservations</div>
          )}
        </div>
      </div>
      {isOpen ? (
        bookings && bookings?.length > 0 ? (
          <div className='shift-table'>
            <table id='bookings'>
              <tr>
                <th>
                  Time of arrival
                  <img
                    className='arrow'
                    alt=''
                    src={up}
                    onClick={() => {
                      setIsReversed(!isReversed);
                    }}
                  />
                </th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Party Size</th>
                <th>Notes</th> <th>Assigned Tables</th>
                <th>Status</th>
              </tr>
              {toggleArray().map((booking) => {
                return (
                  <tr
                    onClick={() => {
                      setIsTableView(true);
                      setSelectedTable(booking);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <td>
                      <div className='time'>
                        {booking.BookingDetails.BookingTime}
                      </div>
                    </td>
                    <td>
                      {booking.CustomerDetails.FirstName}{" "}
                      {booking.CustomerDetails.LastName}
                    </td>
                    <td>
                      {booking.CustomerDetails.ContactDetails[0].PhoneCode}{" "}
                      {booking.CustomerDetails.ContactDetails[0].Number}
                    </td>
                    <td> {booking.CustomerDetails.Email}</td>
                    <td>
                      <img className='user' alt='' src={user} />
                      {booking.BookingDetails.NumberOfPeople}
                    </td>
                    <td>
                      {booking.ReservationNotes
                        ? booking.ReservationNotes
                        : "None"}
                    </td>
                    <td>
                      <div className='tables'>
                        {booking.AssetAllocation.map((table: any) => {
                          return <div className='table'>{table.Name}</div>;
                        })}
                      </div>
                    </td>
                    <td>
                      <div
                        className='status'
                        style={{
                          color: booking.StatusInfo?.Color,
                          background: `${booking.StatusInfo?.Color}1A`,
                        }}
                      >
                        {booking?.StatusInfo?.DisplayName}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        ) : (
          <div className='empty-container'>
            No {selectedFilter.toLowerCase()} bookings available
          </div>
        )
      ) : (
        ""
      )}
    </div>
  );
};
export default ShiftsView;
