import React from "react";
import down from "../../../assets/down-white.png";
import "./NumberInputStyles.scss";
import moment from "moment";

interface TimeInputProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setTimeOpen: () => void;
  newReservation?: boolean;
  date: any;
}

const addMinutes = (time: string, minutes: number) => {
  const [hours, mins] = time.split(":").map(Number);
  const totalMinutes = hours * 60 + mins + minutes;
  const newHours = Math.floor(totalMinutes / 60) % 24;
  const newMinutes = totalMinutes % 60;
  return `${String(newHours).padStart(2, "0")}:${String(newMinutes).padStart(
    2,
    "0"
  )}`;
};

const TimeInput: React.FC<TimeInputProps> = ({
  value,
  setValue,
  setTimeOpen,
  newReservation,
  date,
}) => {
  const roundToNextQuarterHour = (time: moment.Moment): moment.Moment => {
    const minutes = time.minutes();
    const nextQuarterHour = Math.ceil(minutes / 15) * 15;
    if (nextQuarterHour === 60) {
      return time.add(1, "hour").startOf("hour").add(15, "minutes");
    }
    return time.minutes(nextQuarterHour).seconds(0);
  };
  const handleRoundTime = (timeStr: string) => {
    const time = moment(timeStr, "HH:mm");
    const rounded = roundToNextQuarterHour(time);
    return rounded.format("HH:mm");
  };
  const updateTime = () => {
    const dateVal = new Date(date);
    const now = new Date();
    dateVal.setHours(now.getHours());
    dateVal.setMinutes(now.getMinutes());
    dateVal.setSeconds(now.getSeconds());

    return dateVal;
  };
  const getDate = () => {
    return newReservation ? date : updateTime();
  };
  return (
    <div
      className='NumberInput'
      style={{ border: newReservation && newReservation ? "none" : "" }}
    >
      <div className='numb-cont' onClick={setTimeOpen}>
        {value}
      </div>
      <div className='arrow-cont-in'>
        <div className='cont' onClick={() => setValue(addMinutes(value, 15))}>
          <img
            alt=''
            src={down}
            className='image'
            style={{ transform: "rotate(180deg)" }}
          />
        </div>
        <div
          className='cont-2'
          onClick={() => {
            if (
              !(
                moment(getDate()).format("dd-MM-yyyy") ===
                  moment().format("dd-MM-yyyy") &&
                value <= handleRoundTime(moment(getDate()).format("HH:mm"))
              )
            ) {
              setValue(addMinutes(value, -15));
            }
          }}
        >
          <img alt='' src={down} className='image' />
        </div>
      </div>
    </div>
  );
};

export default TimeInput;
