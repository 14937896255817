import axios from "axios";
import globalValues from "../../globalValues";
import { generateToken } from "../../token/requests/Token";
import { saveAs } from "file-saver";
export const downloadCustomerDetails = async () => {
  try {
    const locationID = localStorage.getItem("wrlId");
    if (locationID !== "") {
      const token = await generateToken();
      if (token) {
        return new Promise(async (resolve, reject) => {
          let headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          };
          let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/customer-management/location/${locationID}/excel-download`;
          await axios({
            method: "GET",
            url: url,
            headers,
            responseType: "arraybuffer",
          })
            .then(async (response) => {
              if (response.data !== "") {
                const outputFilename = `customers-${Date.now()}.xlsx`;

                const blob = new Blob([response.data], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                saveAs(blob, outputFilename);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    }
  } catch (error) {
    console.error("Error In getAllTableMerging Data", error);
  }
};

export const searchCustomers = async (value: string) => {
  try {
    const locationID = localStorage.getItem("wrlId");
    if (locationID !== "") {
      const token = await generateToken();
      if (token) {
        return new Promise(async (resolve, reject) => {
          let headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          };
          let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/customer-management/location/${locationID}/findall?SearchKey=${value}&PhoneCode=`;
          await axios({
            method: "GET",
            url: url,
            headers,
          })
            .then(async (response) => {
              resolve(response.data.Result);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    }
  } catch (error) {
    console.error("Error In getAllTableMerging Data", error);
  }
};
