import React, { useState, useEffect } from "react";
import "./FilterTabStyles.scss";
// import more from "../../../assets/more.png";
import res from "../../../assets/res.svg";
import ppl from "../../../assets/ppl.svg";

import SearchInput from "../searchInput/SearchInput";
import FilterComponent from "./FilterComponent";
interface FilterTabProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  bookingDetails: any[];
  setSelectedShift: React.Dispatch<React.SetStateAction<string>>;
  unsortedShifts: any[];
  selectedShift: string;
  selectedFilter: string;
  setSelectedFilter: React.Dispatch<React.SetStateAction<string>>;
  isOpenDrawer: boolean;
  selectedDate: Date;
}
const FilterTab: React.FC<FilterTabProps> = ({
  value,
  setValue,
  bookingDetails,
  setSelectedShift,
  unsortedShifts,
  selectedShift,
  selectedFilter,
  setSelectedFilter,
  isOpenDrawer,
  selectedDate,
}) => {
  const [width, setWidth] = useState(window.innerWidth - 194);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth - 194);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    setValue("");
  }, [selectedDate]);
  const counts = () => {
    let totalNumberOfPeople = 0;
    let totalTableLength = 0;
    bookingDetails?.forEach((shift) => {
      shift?.Bookings?.forEach((booking: any) => {
        totalNumberOfPeople += booking.BookingDetails.NumberOfPeople;
      });
      totalTableLength += shift ? shift?.Bookings?.length : 0;
    });
    return { totalNumberOfPeople, totalTableLength };
  };
  return (
    <div className='FilterTab' style={{ width: isOpenDrawer ? width : "" }}>
      <FilterComponent
        setSelectedShift={setSelectedShift}
        selectedShift={selectedShift}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        unsortedShifts={unsortedShifts}
        view='LIST'
      />
      <div className='right-container'>
        <div className='details-container'>
          <div className='table'>
            <div className='table-res'>
              <img alt='' src={res} />
            </div>
            <div className='count'>{counts().totalTableLength}</div>
          </div>
          <div className='table'>
            <div className='cover'>
              <img alt='' src={ppl} />
            </div>
            <div className='count'>{counts().totalNumberOfPeople}</div>
          </div>
        </div>
        <SearchInput
          value={value}
          onChange={(e) => {
            setValue(e);
          }}
        />
        {/* <div className='more-icon'>
          <img alt='' src={more} />
        </div> */}
      </div>
    </div>
  );
};
export default FilterTab;
