import React, { useEffect, useState } from "react";
import "./CustomerManagementStyles.scss";
import Drawer from "../../components/ReservationComponents/drawer/Drawer";
import SideBar from "../../components/ReservationComponents/sideBar/SideBar";
import TopBar from "../../components/ReservationComponents/topBar/TopBar";
import SearchInput from "../../components/ReservationComponents/searchInput/SearchInput";
import Button from "../../components/button/Button";
import { useSelector } from "react-redux";
import { RootState } from "../../../application/store";
import emailImage from "../../assets/email.svg";
import info from "../../assets/info.svg";
import arrow from "../../assets/arrow.png";
import {
  downloadCustomerDetails,
  searchCustomers,
} from "../../../infra/apis/bookings/requests/CustomerManagement";
import { useNavigate } from "react-router-dom";

const CustomerManagement: React.FC = () => {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(
    localStorage.getItem("drawerOpen")
      ? JSON.parse(localStorage.getItem("drawerOpen") || "false")
      : false
  );
  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const [value, setValue] = useState<string>("");
  const [data, setData] = useState<[]>([]);
  const [searchedCustomers, setSearchedCustomers] = useState<[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<any>("");
  const [screenWidth, setScreenWidth] = useState<any>(992);

  const islocationSwitching = useSelector(
    (state: RootState) => state.booking.islocationSwitching
  );
  const sortAndGroupByFirstName = (arr: any[], searchedValue: string) => {
    arr.sort((a, b) => {
      const firstLetterA = a.Personal.FirstName[0].toUpperCase();
      const firstLetterB = b.Personal.FirstName[0].toUpperCase();
      return firstLetterA.localeCompare(firstLetterB);
    });
    const alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
    const result = alphabet.map((letter) => ({
      key: letter.toUpperCase(),
      contacts: [],
    }));
    arr.forEach((obj: any) => {
      const firstLetter = obj.Personal.FirstName[0].toUpperCase();
      const letterEntry: any = result.find(
        (entry) => entry.key === firstLetter
      );
      if (letterEntry) {
        letterEntry.contacts.push(obj);
      }
    });
    let resultReturn =
      searchedValue === ""
        ? result
        : result.filter((entry) => entry.contacts.length > 0);

    return resultReturn;
  };
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.screen.width);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    localStorage.removeItem("selectedDate");
    setIsLoading(true);
    searchCustomers("").then((res: any) => {
      if (res) {
        setSearchedCustomers(res);
        let value: any = sortAndGroupByFirstName(res, "");
        setData(value);
        if (value[0].contacts[0]) {
          setSelectedCustomer(value[0].contacts[0]);
        }
        setIsLoading(false);
      } else {
        setData([]);
        setIsLoading(false);
      }
    });
  }, []);
  useEffect(() => {
    searchCustomersLocal(value.trim()).then((res: any) => {
      if (res) {
        let ValueData: any = sortAndGroupByFirstName(res, value.trim());
        setData(ValueData);
      } else {
        setData([]);
      }
    });
  }, [value]);
  const searchCustomersLocal = (value: string): Promise<any[]> => {
    return new Promise((resolve) => {
      const filteredCustomers = searchedCustomers.filter((customer: any) => {
        const fullName =
          `${customer.Personal.FirstName} ${customer.Personal.LastName}`.toLowerCase();
        const email = customer.Email.toLowerCase();
        const phoneNumber = `${
          customer.PhoneNo.PhoneCode
        } ${customer.PhoneNo.Number.replace(/\s+/g, "")}`.toLowerCase();
        value = value.toLowerCase();
        return (
          fullName.includes(value) ||
          email.includes(value) ||
          phoneNumber.includes(value)
        );
      });
      resolve(filteredCustomers);
    });
  };
  return (
    <div className='CustomerManagement'>
      {islocationSwitching ? (
        <div className='empty'>
          <div className='loader' />
        </div>
      ) : screenWidth > 900 ? (
        <>
          {isDrawerOpen ? (
            <Drawer
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              selectedMenu={"CUSTOMER"}
            />
          ) : (
            <SideBar
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              selected={"CUSTOMER"}
            />
          )}
          <div
            className='management-content'
            style={{ marginLeft: isDrawerOpen ? "16.25rem" : "5rem" }}
          >
            <TopBar
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              title='Customer Management'
              isOpenDrawer={isDrawerOpen}
            />
            <div className='management-view'>
              <div className='left-view'>
                <div className='search-bar'>
                  <SearchInput
                    value={value}
                    onChange={(e) => {
                      setValue(e);
                      searchCustomersLocal(e).then((res: any) => {
                        if (res) {
                          let value: any = sortAndGroupByFirstName(res, e);
                          setData(value);
                        } else {
                          setData([]);
                        }
                      });
                    }}
                    placeholder='Search contacts..'
                  />
                </div>
                <div className='contacts-container'>
                  <div className='list'>
                    {isLoading ? (
                      <div className='loader' />
                    ) : data.length > 0 ? (
                      data.map((contact: any, index: number) => {
                        return (
                          <div key={index}>
                            <div className='letter-head'>
                              <div className='letter'>{contact.key}</div>
                            </div>
                            <div className='contact-list'>
                              {contact.contacts.length > 0 ? (
                                contact.contacts.map((customer: any) => {
                                  return (
                                    <div
                                      className={`contact ${
                                        selectedCustomer.ID === customer.ID
                                          ? "selected"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        setSelectedCustomer(customer);
                                      }}
                                    >
                                      {customer?.Personal?.FirstName}{" "}
                                      {customer?.Personal?.LastName}
                                    </div>
                                  );
                                })
                              ) : (
                                <div className='empty-customer'>
                                  No customers exist
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className='empty-container'>
                        No contacts available
                      </div>
                    )}
                  </div>
                  {!isLoading && (
                    <div className='button-container'>
                      <div className='btn-container'>
                        <Button
                          text='Add Customer'
                          auth
                          height='46px'
                          disable
                        />
                        {data.length > 0 && (
                          <>
                            <div className='margin-top' />
                            <Button
                              text='Download'
                              border
                              height='46px'
                              onClick={downloadCustomerDetails}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='right-view'>
                {isLoading ? (
                  <div className='loader' />
                ) : selectedCustomer === "" ? (
                  ""
                ) : (
                  <>
                    <div className='header'>
                      <div className='head'>Personal Details</div>
                    </div>
                    <div className='details'>
                      <div className='profile-header'>
                        <div className='profile'>
                          <div className='avatar'>
                            {selectedCustomer?.Personal?.FirstName?.charAt(0)}
                          </div>
                          <div className='name-container'>
                            <div className='name'>
                              {selectedCustomer?.Personal?.FirstName}{" "}
                              {selectedCustomer?.Personal?.LastName}
                            </div>
                            <div className='email'>
                              <img alt='' src={emailImage} />
                              {selectedCustomer?.Email}
                            </div>
                          </div>
                        </div>
                        <div className='button-cont'>
                          <Button
                            text='New Reservation'
                            auth
                            height='42px'
                            icon
                            padding='1rem'
                            width='180px'
                            onClick={() => {
                              navigate(
                                `/v1/booking/new-reservation?firstName=${selectedCustomer?.Personal?.FirstName}&lastName=${selectedCustomer?.Personal?.LastName}&email=${selectedCustomer?.Email}&phone=${selectedCustomer?.PhoneNo?.Number}`
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className='details-conatiner'>
                        <div className='container'>
                          <div className='header-det'>Profile</div>
                          <div className='container-wrapper'>
                            <div className='details-table-cont'>
                              <div className='details-header'>
                                <img alt='' src={info} />
                                Personal Information
                              </div>
                              <div className='details-row'>
                                <div className='row-cont'>
                                  <div className='head-text'>First Name</div>
                                  <div className='sub-text'>
                                    {selectedCustomer?.Personal?.FirstName}
                                  </div>
                                </div>
                                <div className='row-cont'>
                                  <div className='head-text'>Last Name</div>
                                  <div className='sub-text'>
                                    {selectedCustomer?.Personal?.LastName}
                                  </div>
                                </div>
                                <div className='row-cont'>
                                  <div className='head-text'>Middle Name</div>
                                  <div className='sub-text'>
                                    {selectedCustomer?.Personal?.MiddleName}
                                  </div>
                                </div>
                                <div className='row-cont'>
                                  <div className='head-text'>Gender</div>
                                  <div className='sub-text'>----</div>
                                </div>{" "}
                                <div className='row-cont'>
                                  <div className='head-text'>Birth day</div>
                                  <div className='sub-text'>----</div>
                                </div>{" "}
                                <div className='row-cont'>
                                  <div className='head-text'>Anniversary</div>
                                  <div className='sub-text'>----</div>
                                </div>{" "}
                                <div className='row-cont'>
                                  <div className='head-text'>Address</div>
                                  <div className='sub-text'>----</div>
                                </div>
                              </div>
                            </div>
                            <div className='details-table-cont'>
                              <div className='details-header'>
                                <img alt='' src={info} />
                                Contact Information
                              </div>
                              <div className='details-row'>
                                <div className='row-cont'>
                                  <div className='head-text'>Email</div>
                                  <div className='sub-text'>
                                    {selectedCustomer?.Email}
                                  </div>
                                </div>
                                <div className='row-cont'>
                                  <div className='head-text'>Phone number</div>
                                  <div className='sub-text'>
                                    ({selectedCustomer?.PhoneNo?.PhoneCode}){" "}
                                    {selectedCustomer?.PhoneNo?.Number}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='details-table-cont'>
                              <div className='details-header'>
                                <img alt='' src={info} />
                                Professional Information
                              </div>
                              <div className='details-row'>
                                <div className='row-cont'>
                                  <div className='head-text'>Job Title</div>
                                  <div className='sub-text'>----</div>
                                </div>
                                <div className='row-cont'>
                                  <div className='head-text'>Company Name</div>
                                  <div className='sub-text'>----</div>
                                </div>
                              </div>
                            </div>
                            <div className='details-table-cont'>
                              <div className='details-header'>
                                <img alt='' src={info} />
                                Alternative
                              </div>
                              <div className='details-row'>
                                <div className='row-cont'>
                                  <div className='head-text'>
                                    Alternative Email
                                  </div>
                                  <div className='sub-text'></div>
                                </div>
                                <div className='row-cont'>
                                  <div className='head-text'>
                                    Alternative Phone Number
                                  </div>
                                  <div className='sub-text'></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='container'>
                          <div className='header-det'>Others</div>
                          <div className='container-wrapper'>
                            <div className='others-table-cont'>
                              <div className='expand-header '>
                                Guest Tags
                                <img alt='' src={arrow} />
                              </div>
                              <div className='line' />
                              <div className='expand-header '>
                                Notes
                                <img alt='' src={arrow} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className='landscape'>
          Please rotate your device to landscape mode
        </div>
      )}
    </div>
  );
};
export default CustomerManagement;
