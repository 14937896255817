import React from "react";
import "./ButtonStyles.scss";
import add from "../../assets/add.svg";
interface ButtonProps {
  text: string;
  onClick?: () => void;
  auth?: boolean;
  loading?: boolean;
  height?: string;
  width?: string;
  padding?: string;
  disable?: boolean;
  fontWeight?: number;
  icon?: boolean;
  border?: boolean;
}
const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  auth,
  loading,
  height,
  width,
  padding,
  disable,
  fontWeight,
  icon,
  border,
}) => {
  return (
    <button
      className={`Button ${
        auth ? "auth-gradient" : border ? "border-btn" : ""
      }`}
      onClick={onClick}
      type='submit'
      style={{
        height: height,
        width: width,
        padding: padding,
        background: disable ? "#E5E5E5" : "",
        fontWeight: fontWeight,
        color: disable ? "#727272" : "",
      }}
    >
      {icon && <img alt='' src={add} />}

      {loading ? <div className='loading-ic' /> : text}
    </button>
  );
};
export default Button;
