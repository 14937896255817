import React from "react";
import "./NetworkStatus.scss";
import { FaExclamationTriangle } from "react-icons/fa";
const NetworkStatus: React.FC = () => {
  return (
    <div className='NetworkStatus'>
      <FaExclamationTriangle style={{ color: "red" }} size={18} />

      <div>
        <div>You are offline!</div>
        <div className='sub-text'>Please check your internet connection.</div>
      </div>
    </div>
  );
};

export default NetworkStatus;
