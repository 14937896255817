import React from "react";
import danger from "../../../../assets/close.png";
import warning from "../../../../assets/alert.png";
import close from "../../../../assets/close.png";

interface AlertProps {
  type: string;
  content: string;
  closeIcon?: boolean;
  onClick?: any;
  subContent?: string;
}
const Alert: React.FC<AlertProps> = ({
  type,
  content,
  closeIcon,
  onClick,
  subContent,
}) => {
  return (
    <div className={`alert-${type}`}>
      <div className='left-content'>
        <div className='cancel'>
          <img
            alt=''
            src={type === "danger" ? danger : warning}
            className={type}
          />
        </div>
      </div>
      <div className='right-content'>
        {content}
        {subContent && (
          <>
            <br />
            <br />
          </>
        )}

        {subContent}
      </div>
      {closeIcon && (
        <img alt='' src={close} className='close' onClick={onClick} />
      )}
    </div>
  );
};
export default Alert;
