import React, { useEffect, useState } from "react";

import Table from "./Table";
import { useDrag } from "react-dnd";
import copy from "../../../../assets/copy.svg";
import rotate from "../../../../assets/rotate-left.svg";
import trash from "../../../../assets/trash.svg";
import { v4 as uuidv4 } from "uuid";
import IncreaseButton from "../../increaseButton/IncreaseButton";
import tick from "../../../../assets/checkmark.png";
import NumberInput from "../../numberInput/NumberInput";
import angleIC from "../../../../assets/angle.svg";
import widthIC from "../../../../assets/width.svg";
import heightIC from "../../../../assets/height.svg";
import xIC from "../../../../assets/x-axis.svg";
import yIC from "../../../../assets/y-axis.svg";
import img from "../../../../assets/delete.png";

import Button from "../../../button/Button";
import globalValues from "../../../../../infra/apis/globalValues";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../application/store";
import { setFloorLayoutData } from "../../../../../application/reducer/bookingSlice";

interface TableComponentProps {
  item: any;
  selectedTable: any;
  setSelectedTable: React.Dispatch<React.SetStateAction<any>>;
  selectedFloor: any;
  setSelectedFloor: React.Dispatch<React.SetStateAction<any>>;
  setIsReservationWarningOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TableComponent: React.FC<TableComponentProps> = ({
  item,
  selectedTable,
  setSelectedTable,
  selectedFloor,
  setSelectedFloor,
  setIsReservationWarningOpen,
}) => {
  const [name, setName] = useState<string>("");
  const [min, setMin] = useState<number>(1);
  const [max, setMax] = useState<number>(4);
  const [onlineStatus, setOnlineStatus] = useState<boolean>(false);
  const [xAxis, setXAxis] = useState<any>("");
  const [yAxis, setYAxis] = useState<any>("");
  const [width, setWidth] = useState<any>("");
  const [height, setHeight] = useState<any>("");
  const [angle, setAngle] = useState<any>("");
  const [check, setCheck] = useState<boolean>(false);
  const [priority, setPriority] = useState<number>(4);
  const [errors, setErrors] = useState({
    name: false,
    height: false,
    width: false,
    exceed: false,
    angle: false,
    reservation: false,
    tableName: false,
  });
  const floorLayoutData = useSelector(
    (state: RootState) => state.booking.floorLayoutData
  );
  const [, drag] = useDrag({
    type: "table",
    item: { ...item, isNew: false },
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedTable) {
      setOnlineStatus(selectedTable.IsOnline);
      setMin(selectedTable.Covers.Minimum);
      setMax(selectedTable.Covers.Maximum);
      setName(selectedTable.TableName);
      setPriority(selectedTable.Priority);
      setXAxis(selectedTable.Dimensions.XValue.toString());
      setYAxis(selectedTable.Dimensions.YValue.toString());
      setHeight(selectedTable.Dimensions.Height.toString());
      setWidth(selectedTable.Dimensions.Width.toString());
      setAngle(selectedTable.Dimensions.Angle.toString());
      clearErrors();
    }
  }, [selectedTable]);
  const getReservations = (max: number) => {
    if (selectedTable.Reservations.length > 0) {
      let isTrue = selectedTable.Reservations.some(
        (reservation: any) => max < reservation?.BookingDetails.NumberOfPeople
      );
      return isTrue;
    } else {
      return false;
    }
  };
  const validateForm = (): boolean => {
    let valid = true;
    const newErrors = {
      name: false,
      height: false,
      width: false,
      exceed: false,
      angle: false,
      reservation: false,
      tableName: false,
    };

    const regex = /^-?\d+$/;
    const existingTables = floorLayoutData
      .flatMap((floor) => floor.Tables)
      .filter((table) => table.ID !== selectedTable.ID)
      .map((table: any) => table.TableName);

    if (name.trim() === "") {
      newErrors.name = true;
      valid = false;
    }
    if (Number(height) < 95) {
      newErrors.height = true;
      valid = false;
    }
    if (Number(width) < 100) {
      newErrors.width = true;
      valid = false;
    }
    if (Number(min) >= Number(max)) {
      newErrors.exceed = true;
      valid = false;
    }
    if (!(Math.abs(Number(angle)) <= 360)) {
      newErrors.angle = true;
      valid = false;
    }
    if (!regex.test(angle)) {
      newErrors.angle = true;
      valid = false;
    }
    if (getReservations(Number(max))) {
      newErrors.reservation = true;
      valid = false;
    }
    if (existingTables.includes(name.trim())) {
      newErrors.tableName = true;
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const rotateTable = (item: any) => {
    const index = selectedFloor.Tables.findIndex(
      (table: any) => table.ID === item.ID
    );

    if (index !== -1) {
      let angle = (item?.Dimensions?.Angle - 90) % 360;
      let updatedTable = {
        ...item,
        Dimensions: {
          ...item.Dimensions,
          Angle: angle,
        },
      };
      const updatedTables = [
        ...selectedFloor.Tables.slice(0, index),
        updatedTable,
        ...selectedFloor.Tables.slice(index + 1),
      ];
      const updatedFloor = {
        ...selectedFloor,
        Tables: updatedTables,
      };

      setSelectedTable(updatedTable);
      setSelectedFloor(updatedFloor);
    }
  };
  const duplicateTable = (item: any) => {
    const index = selectedFloor.Tables.findIndex(
      (table: any) => table.ID === item.ID
    );

    if (index !== -1) {
      const duplicatedTable = {
        ...item,
        Dimensions: {
          ...item.Dimensions,
          XValue: item.Dimensions.XValue + 100,
          TableName: (selectedFloor.Tables.length + 1).toString(),
        },
        TableName: (selectedFloor.Tables.length + 1).toString(),
        ID: uuidv4().replace(/-/g, "").substring(0, 24),
        Floor: {
          CloudRefNo: selectedFloor?.FloorID,
        },
        IsActive: globalValues.environment.ENV !== "production" ? 1 : 0,
        Reservations: [],
      };
      const updatedTables = [...selectedFloor.Tables, duplicatedTable];
      const updatedFloor = {
        ...selectedFloor,
        Tables: updatedTables,
      };
      setSelectedTable(null);
      setSelectedFloor(updatedFloor);
    }
  };
  const handleChange = (name: any) => {
    setErrors({
      ...errors,
      [name]: false,
    });
  };
  const getTablePopup = () => {
    return (
      <div
        className='details'
        style={{
          left: item?.Dimensions.Width,
        }}
      >
        <div className='top'>
          <div className='head'>Table Details</div>
          <div className='name'>
            <div>Table Name</div>
            <input
              value={name}
              onChange={(e) => {
                handleChange("name");
                handleChange("tableName");
                setName(e.target.value);
              }}
            />
          </div>
          <div className='name'>
            <div>Min. Covers</div>
            <IncreaseButton value={min} setValue={setMin} min={1} />
          </div>{" "}
          <div className='name'>
            <div>Max. Covers</div>
            <IncreaseButton value={max} setValue={setMax} min={2} />
          </div>{" "}
          <div className='online'>
            <div>Online</div>
            <div className='status'>
              {onlineStatus ? (
                <div className='active'>Active</div>
              ) : (
                <div className='inactive'>Inactive</div>
              )}
              <label className='switch'>
                <input
                  type='checkbox'
                  checked={onlineStatus}
                  onClick={() => {
                    setOnlineStatus(!onlineStatus);
                  }}
                />
                <span className='slider round'></span>
              </label>
            </div>
          </div>
        </div>
        <div className='bottom'>
          <div className='head'>
            Advanced Settings
            <div
              className='check'
              onClick={() => {
                setCheck(!check);
              }}
            >
              {check && (
                <div className='checked'>
                  <img alt='' src={tick} />
                </div>
              )}
            </div>
          </div>
          {check && (
            <>
              <div className='prio'>
                Priority
                <div className='number'>
                  <NumberInput
                    value={priority}
                    setValue={setPriority}
                    setCoversOpen={() => {}}
                  />
                </div>
              </div>
              <div className='head-dim'>Dimension</div>
              <div className='flex-cont'>
                <div className='left'>
                  <div>X-axis</div>
                  <div className='input-container'>
                    <img alt='' src={xIC} />
                    <input
                      type='number'
                      value={xAxis}
                      onChange={(e) => {
                        setXAxis(e.target.value);
                      }}
                      min={0}
                    />
                  </div>
                </div>
                <div className='right'>
                  <div>Y-axis</div>
                  <div className='input-container'>
                    <img alt='' src={yIC} className='y' />
                    <input
                      type='number'
                      value={yAxis}
                      onChange={(e) => {
                        setYAxis(e.target.value);
                      }}
                      min={0}
                    />
                  </div>
                </div>
              </div>
              <div className='flex-cont'>
                <div className='left'>
                  <div>Width</div>
                  <div className='input-container'>
                    <img alt='' src={widthIC} />
                    <input
                      type='number'
                      value={width}
                      onChange={(e) => {
                        handleChange("width");

                        setWidth(e.target.value);
                      }}
                      min={50}
                    />
                  </div>
                </div>
                <div className='right'>
                  <div>Height</div>
                  <div className='input-container'>
                    <img alt='' src={heightIC} className='height' />
                    <input
                      type='number'
                      value={height}
                      onChange={(e) => {
                        handleChange("height");

                        setHeight(e.target.value);
                      }}
                      min={45}
                    />
                  </div>
                </div>
              </div>
              <div className='flex-cont'>
                <div className='angle'>
                  <div>Angle</div>
                  <div className='input-container'>
                    <img alt='' src={angleIC} />
                    <input
                      type='number'
                      value={angle}
                      onChange={(e) => {
                        handleChange("angle");
                        setAngle(e.target.value);
                      }}
                    />
                    °
                  </div>
                </div>
              </div>
            </>
          )}
          <div className='error-cont'>
            {errors.tableName && (
              <div className='error'>Table name {name} already exists</div>
            )}
            {errors.name && (
              <div className='error'>Table name cannot be empty</div>
            )}
            {errors.exceed && (
              <div className='error'>
                The number of minimum covers must be less than the number of
                maximum covers.
              </div>
            )}
            {errors.width && (
              <div className='error'>Minimum width must be 100</div>
            )}
            {errors.height && (
              <div className='error'>Minimum height must be 95</div>
            )}
            {errors.angle && <div className='error'>Invalid angle</div>}
            {errors.reservation && (
              <div className='error'>
                The maximum number of covers cannot be less than the capacity of
                any existing reservation.
              </div>
            )}
          </div>
          <div className='btn-container'>
            <Button auth text='Apply' height='34px' onClick={saveTable} />
          </div>
        </div>
      </div>
    );
  };

  const saveTable = () => {
    const index = selectedFloor.Tables.findIndex(
      (table: any) => table.ID === item.ID
    );

    if (index !== -1) {
      let updatedTable = {
        ...selectedTable,
        Covers: {
          Minimum: Number(min),
          Maximum: Number(max),
        },
        TableName: name,
        IsOnline: onlineStatus,
        Priority: priority,
        Dimensions: {
          ...item.Dimensions,
          XValue: Number(xAxis),
          YValue: Number(yAxis),
          Width: Number(width),
          Height: Number(height),
          Angle: Number(angle),
        },
      };
      if (validateForm()) {
        const updatedTables = [...selectedFloor.Tables];
        updatedTables[index] = updatedTable;
        const updatedFloor = {
          ...selectedFloor,
          Tables: updatedTables,
        };

        setSelectedTable(null);
        setSelectedFloor(updatedFloor);
      }
    }
  };
  const deleteTable = (item: any) => {
    const index = selectedFloor.Tables.findIndex(
      (table: any) => table.ID === item.ID
    );
    if (index !== -1) {
      if (item.isNew) {
        let filtered = selectedFloor.Tables.filter(
          (table: any) => table.ID !== item.ID
        );
        const updatedFloor = {
          ...selectedFloor,
          Tables: filtered,
        };
        setSelectedFloor(updatedFloor);
        const floorIndex = floorLayoutData.findIndex(
          (item) => item.FloorID === selectedFloor?.FloorID
        );
        const updatedList = [
          ...floorLayoutData.slice(0, floorIndex),
          updatedFloor,
          ...floorLayoutData.slice(floorIndex + 1),
        ];
        dispatch(setFloorLayoutData(updatedList));
      } else {
        let updatedTable = {
          ...item,
          IsActive: globalValues.environment.ENV !== "production" ? 0 : 1,
        };
        const updatedTables = [
          ...selectedFloor.Tables.slice(0, index),
          updatedTable,
          ...selectedFloor.Tables.slice(index + 1),
        ];
        const updatedFloor = {
          ...selectedFloor,
          Tables: updatedTables,
        };

        setSelectedTable(updatedTable);
        setSelectedFloor(updatedFloor);
        const floorIndex = floorLayoutData.findIndex(
          (item) => item.FloorID === selectedFloor?.FloorID
        );
        const updatedList = [
          ...floorLayoutData.slice(0, floorIndex),
          updatedFloor,
          ...floorLayoutData.slice(floorIndex + 1),
        ];
        dispatch(setFloorLayoutData(updatedList));
      }
    }
  };
  useEffect(() => {
    handleChange("exceed");
  }, [min]);
  const clearErrors = () => {
    const newErrors = {
      name: false,
      height: false,
      width: false,
      exceed: false,
      angle: false,
      reservation: false,
      tableName: false,
    };
    setErrors(newErrors);
  };
  return (
    <div
      className='conat'
      style={{
        left: `${item?.Dimensions.XValue}px`,
        top: `${item?.Dimensions.YValue}px`,
        height: item?.Dimensions.Height,
        width: item?.Dimensions.Width,
      }}
    >
      {selectedTable && selectedTable?.ID === item.ID && (
        <div className='action'>
          <div
            className='item'
            onClick={() => {
              rotateTable(item);
            }}
          >
            <img alt='' src={rotate} />
          </div>
          <div
            className='item'
            onClick={() => {
              duplicateTable(item);
            }}
          >
            <img alt='' src={copy} />
          </div>
          <div
            className='item'
            onClick={() => {
              if (item?.Reservations?.length > 0) {
                setIsReservationWarningOpen(true);
              } else {
                deleteTable(item);
              }
            }}
          >
            <img alt='' src={trash} />
          </div>
        </div>
      )}
      {selectedTable && selectedTable?.ID === item.ID && getTablePopup()}

      <div
        ref={drag}
        className={`table-layout-bg ${
          selectedTable && selectedTable?.ID === item.ID
            ? "table-layout-bg-selected "
            : ""
        }`}
        style={{
          transform: `rotate(${item?.Dimensions.Angle}deg)`,
        }}
        onClick={() => {
          setSelectedTable(item);
        }}
      >
        <Table
          height={item?.Dimensions.Height - 10}
          width={item.Dimensions.Width - 10}
          chairCount={item.Covers.Maximum - 2}
          type={item.Dimensions.Shape}
          name={item.TableName}
          isLocked={false}
          isView={false}
          isEdit={true}
          min={item.Covers.Minimum}
          max={item.Covers.Maximum}
        />
      </div>
    </div>
  );
};
export default TableComponent;
