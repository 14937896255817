import axios from "axios";
import globalValues from "../../globalValues";
import { generateToken } from "../../token/requests/Token";

export const deleteShift = async (id: any) => {
  try {
    const token = await generateToken();
    if (token) {
      return new Promise(async (resolve, reject) => {
        let headers = {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        };
        let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/shift/${id}/delete`;
        await axios({
          method: "DELETE",
          url: url,
          headers,
        })
          .then((response) => {
            if (response.data.Status) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  } catch (error) {
    console.error("Error In delete Shift Data", error);
  }
};
export const addShiftDetails = async (data: any) => {
  try {
    const token = await generateToken();
    if (token) {
      return new Promise(async (resolve, reject) => {
        let headers = {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        };
        let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/shift/add`;
        await axios({
          method: "POST",
          url: url,
          headers,
          data,
        })
          .then((response) => {
            if (response.data.Status) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  } catch (error) {
    console.error("Error In add Shift Data", error);
  }
};
export const updateShiftDetails = async (data: any) => {
  try {
    const token = await generateToken();
    if (token) {
      return new Promise(async (resolve, reject) => {
        let headers = {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        };
        let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/shift/location/update`;
        await axios({
          method: "PUT",
          url: url,
          headers,
          data,
        })
          .then((response) => {
            if (response.data.Status) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  } catch (error) {
    console.error("Error In delete Shift Data", error);
  }
};
