import React from "react";
import FilterTab from "../../../components/ReservationComponents/filterTab/FilterTab";
import ShiftsView from "../../../components/ReservationComponents/shiftsView/ShiftsView";

interface ReservationListViewComponentProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  getshiftsWithBookings: any;
  setSelectedShift: React.Dispatch<React.SetStateAction<string>>;
  getUnsortedShifts: any;
  selectedShift: string;
  selectedFilter: string;
  setSelectedFilter: React.Dispatch<React.SetStateAction<string>>;
  getSeatedBookings: any;
  setSelectedTable: React.Dispatch<React.SetStateAction<any>>;
  setIsTableView: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenDrawer: boolean;
  selectedDate: Date;
}

const ReservationListViewComponent: React.FC<
  ReservationListViewComponentProps
> = ({
  value,
  setValue,
  getshiftsWithBookings,
  setSelectedShift,
  getUnsortedShifts,
  selectedShift,
  selectedFilter,
  setSelectedFilter,
  getSeatedBookings,
  setIsTableView,
  setSelectedTable,
  isOpenDrawer,
  selectedDate,
}) => {
  return (
    <div className='list-view'>
      <FilterTab
        value={value}
        setValue={setValue}
        bookingDetails={getshiftsWithBookings()}
        setSelectedShift={setSelectedShift}
        unsortedShifts={getUnsortedShifts()}
        selectedShift={selectedShift}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        isOpenDrawer={isOpenDrawer}
        selectedDate={selectedDate}
      />
      {/* <FloatingTab /> */}
      <div className='table-view'>
        {getshiftsWithBookings().length > 0 ? (
          getshiftsWithBookings().map((shift: any) => {
            return (
              <div>
                <ShiftsView
                  shift={shift?.Name}
                  bookings={getSeatedBookings(shift?.Bookings)}
                  unsortedBookings={shift?.Bookings}
                  selectedFilter={selectedFilter}
                  setIsTableView={setIsTableView}
                  setSelectedTable={setSelectedTable}
                />
              </div>
            );
          })
        ) : (
          <div className='empty-container'>No bookings available</div>
        )}
      </div>
    </div>
  );
};
export default ReservationListViewComponent;
