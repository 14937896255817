import React, { useState } from "react";
import "./ModalStyles.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../application/store";

interface RenameModalProps {
  isOpen: boolean;
  onChange: () => void;
  onClose: () => void;
  isLoading: boolean;
  setName: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  selectedFloor: any;
}

const RenameModal: React.FC<RenameModalProps> = ({
  isOpen,
  onChange,
  onClose,
  isLoading,
  name,
  setName,
  selectedFloor,
}) => {
  const [isError, setIsError] = useState(false);
  const floorLayoutData = useSelector(
    (state: RootState) => state.booking.floorLayoutData
  );

  const checkName = () => {
    let nameArray = floorLayoutData
      .filter((floor: any) => floor.FloorID !== selectedFloor.FloorID)
      .map((floor: any) => floor.DisplayName.trim());
    if (nameArray.includes(name.trim())) {
      setIsError(true);
    } else {
      setIsError(false);
      onChange();
    }
  };
  return (
    <div className='modal-overlay'>
      <div className='modal-content' onClick={(e) => e.stopPropagation()}>
        <div className='header'>Rename the room</div>

        <div className='input'>
          <input
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setIsError(false);
            }}
          />
        </div>
        {isError && <div className='error'>Room name already exists</div>}

        <div className='btn-container'>
          <div className='cancel' onClick={onClose}>
            Cancel
          </div>
          <div className='rename' onClick={checkName}>
            {isLoading ? <div className='loading-ic' /> : "Rename"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenameModal;
