import React, { useEffect, useState } from "react";
import down from "../../../assets/down-white.png";
import "./NumberInputStyles.scss";

interface DurationInputProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setDurationOpen: () => void;
  interval: number;
  newReservation?: boolean;
}

const DurationInput: React.FC<DurationInputProps> = ({
  value,
  setValue,
  interval,
  setDurationOpen,
  newReservation,
}) => {
  const [time, setTime] = useState(interval);

  const formatTime = (totalMinutes: number) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return hours > 0 ? `${hours} h ${minutes} mins` : `${minutes} mins`;
  };
  useEffect(() => {
    setTime(interval);
  }, [interval]);
  useEffect(() => {
    setValue(formatTime(time));
  }, [time, setValue]);

  return (
    <div
      className='NumberInput'
      style={{ border: newReservation && newReservation ? "none" : "" }}
    >
      <div className='numb-cont' onClick={setDurationOpen}>
        {value}
      </div>
      <div className='arrow-cont-in'>
        <div
          className='cont'
          onClick={() => {
            setTime((prevTime) => prevTime + 15);
          }}
        >
          <img alt='' src={down} className='image' />
        </div>
        <div
          className='cont-2'
          onClick={() => {
            if (time !== 15) {
              setTime((prevTime) => (prevTime > 0 ? prevTime - 15 : 0));
            }
          }}
        >
          <img alt='' src={down} className='image' />
        </div>
      </div>
    </div>
  );
};

export default DurationInput;
