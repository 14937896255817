import React, { useEffect } from "react";
import "./ModalStyles.scss";
import img from "../../../assets/warn.svg";

interface WarningModalProps {
  onClose: () => void;
  onOk: () => void;
  header: string;
  isLoading?: boolean;
  secondary: boolean;
  primaryText: string;
  secondaryText?: string;
}

const BookingFailed: React.FC<WarningModalProps> = ({
  onClose,
  onOk,
  isLoading,
  secondary,
  primaryText,
  secondaryText,
}) => {
  const data = localStorage.getItem("FAILED_RESERVE")
    ? JSON.parse(localStorage.getItem("FAILED_RESERVE") || "")
    : "";
  useEffect(() => {
    if (data === "") {
      onClose();
    }
  }, []);
  return (
    <div className='modal-overlay'>
      <div className='modal-content' onClick={(e) => e.stopPropagation()}>
        <div className='header'>
          <img alt='' src={img} className='failed' />
          Reservation Failed [You are Offline]
        </div>
        <div className='content-2'>
          The reservation for
          <span
            style={{ color: "#4f4e4e", fontWeight: 600, marginLeft: "10px" }}
          >
            {data.firstName} {data.lastName} on {data.date}, for a party size of{" "}
            {data.guest} at table(s){" "}
            {data?.assets?.flatMap((table: any) => table.Name).join(", ")}
          </span>{" "}
          was unsuccessful. Would you like to try making the reservation again?
        </div>
        <div className='btn-container'>
          {secondary && (
            <div className='close' onClick={onClose}>
              {secondaryText || "Discard"}
            </div>
          )}
          <div className='failed-warning' onClick={onOk}>
            {isLoading ? <div className='loading-ic' /> : primaryText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingFailed;
