import React from "react";
import "./DateModalStyles.scss";
import close from "../../../../assets/close.png";
import DatePickerComponet from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RootState } from "../../../../../application/store";
import { useSelector } from "react-redux";
interface DateModalProps {
  isOpen: boolean;
  onClose: () => void;
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<any>>;
  setDateOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit: boolean;
}

const DateModal: React.FC<DateModalProps> = ({
  isOpen,
  onClose,
  date,
  setDate,
  setDateOpen,
  isEdit,
}) => {
  const organizationDetails = useSelector(
    (state: RootState) => state.booking.organizationDetails
  );
  const openingHours = organizationDetails?.OpeningHours?.filter(
    (day: any) => !day.Closed
  ).map((day: any) => day.Day);
  const getDayString = (day: number): string => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return daysOfWeek[day];
  };
  const isOpenday = (date: any) => {
    const dayString = getDayString(date.getDay());
    return openingHours?.includes(dayString) || false;
  };
  return (
    <div className='date-modal-overlay' onClick={onClose}>
      <div
        className='date-modal-content'
        onClick={(e) => e.stopPropagation()}
        style={{ left: isEdit ? "37.2%" : "", top: isEdit ? "2rem" : "" }}
      >
        <div className='header'>
          <img alt='' src={close} onClick={onClose} />
        </div>
        <div className='content'>
          <DatePickerComponet
            selected={date}
            onChange={(date) => {
              setDate(date);
              setDateOpen(false);
            }}
            dateFormat={"dd LLLL YYYY"}
            open={true}
            minDate={new Date()}
            className='iner'
            // filterDate={isOpenday}
          />
        </div>
      </div>
    </div>
  );
};

export default DateModal;
