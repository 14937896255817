import React, { useState } from "react";
import { format, addDays, subDays } from "date-fns";
import "./DatePickerStyles.scss";
import arrow from "../../../assets/arrow.png";
import calendar from "../../../assets/date.png";
import DatePickerComponet from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
interface DatePickerProps {
  selectedDate: any;
  setSelectedDate: React.Dispatch<React.SetStateAction<any>>;
}
const DatePicker: React.FC<DatePickerProps> = ({
  selectedDate,
  setSelectedDate,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handlePreviousDay = () => {
    setSelectedDate(subDays(selectedDate, 1));
    localStorage.setItem(
      "selectedDate",
      JSON.stringify(subDays(selectedDate, 1))
    );
  };
  const handleNextDay = () => {
    setSelectedDate(addDays(selectedDate, 1));
    localStorage.setItem(
      "selectedDate",
      JSON.stringify(addDays(selectedDate, 1))
    );
  };

  return (
    <div className='DatePicker'>
      <div onClick={handlePreviousDay} className='backarrow'>
        <img alt='' src={arrow} />
      </div>
      <div className='date'>
        <img alt='' src={calendar} />
        <div onClick={() => setIsOpen(!isOpen)} style={{ cursor: "pointer" }}>
          {format(selectedDate, "dd LLLL yyyy")}
        </div>
        {isOpen ? (
          <div>
            <DatePickerComponet
              selected={selectedDate}
              onChange={(date) => {
                setSelectedDate(date);
                localStorage.setItem("selectedDate", JSON.stringify(date));
                setIsOpen(false);
              }}
              dateFormat={"dd LLLL YYYY"}
              open={isOpen}
              onClickOutside={() => setIsOpen(false)}
            />
          </div>
        ) : (
          <div />
        )}
      </div>
      <div onClick={handleNextDay} className='rightarrow'>
        <img alt='' src={arrow} />
      </div>
    </div>
  );
};

export default DatePicker;
