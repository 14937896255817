import React from "react";
import table from "../../../../assets/tbl.png";
import close from "../../../../assets/close.png";
import "./TableDetailsStyles.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../application/store";
import { setAllocatedTables } from "../../../../../application/reducer/bookingSlice";

const SelectedTables: React.FC = () => {
  const dispatch = useDispatch();
  const allocatedTables = useSelector(
    (state: RootState) => state.booking.allocatedTables
  );

  return (
    <div className='selected-tables'>
      <div className='table-det'>
        <img alt='' src={table} />
        <div className='text-cont'>
          <div className='header'>Tables</div>
          <div className='sub'>Selected</div>
        </div>
      </div>
      {allocatedTables.length > 0 ? (
        <div className='grid-tables'>
          {allocatedTables.map((table: any) => {
            return (
              <div className='table-item'>
                {table.Name}
                <div
                  className='close'
                  onClick={() => {
                    let newArry = allocatedTables.filter(
                      (tableItem) => tableItem.RefNo !== table.RefNo
                    );
                    dispatch(setAllocatedTables(newArry));
                  }}
                >
                  <img alt='' src={close} />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className='empty-cont'>No Table Selected</div>
      )}
    </div>
  );
};
export default SelectedTables;
