import React, { useEffect, useRef, useState } from "react";
import menu from "../../../assets/menu.svg";
import copy from "../../../assets/copy.svg";
import trash from "../../../assets/trash.svg";
import rename from "../../../assets/rename.svg";
import { useDrag } from "react-dnd";
import { useSelector } from "react-redux";
import { RootState } from "../../../../application/store";
import _ from "lodash";

interface RoomLayoutProps {
  floor: any;
  selectedFloor: any;
  setSelectedFloor: React.Dispatch<React.SetStateAction<any>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setIsRenameOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeleteOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaveChangesOpen: React.Dispatch<React.SetStateAction<boolean>>;
  duplicateRoom: () => void;
  index: number;
  setIsRoomDeleteWarningOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectingFloor: React.Dispatch<React.SetStateAction<any>>;
}
const RoomLayout: React.FC<RoomLayoutProps> = ({
  floor,
  selectedFloor,
  setSelectedFloor,
  duplicateRoom,
  setName,
  setIsDeleteOpen,
  setIsRenameOpen,
  index,
  setIsSaveChangesOpen,
  setIsRoomDeleteWarningOpen,
  setSelectingFloor,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const defaultFloorLayoutData = useSelector(
    (state: RootState) => state.booking.defaultFloorLayoutData
  );
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [, ref] = useDrag({
    type: "Room",
    item: { index, id: floor.FloorID, floor },
  });
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className='cont' ref={ref}>
      <div className='floor-container-l'>
        <div
          className={`content-r ${
            selectedFloor?.FloorID === floor.FloorID
              ? "content-selected-r "
              : ""
          }`}
          onClick={() => {
            setSelectingFloor(floor);
            const findFloor = defaultFloorLayoutData.find(
              (floor) => floor.FloorID === selectedFloor.FloorID
            );
            if (
              _.isEqual(selectedFloor?.Tables, findFloor?.Tables) &&
              !selectedFloor.isNew
            ) {
              setSelectedFloor(floor);
            } else {
              setIsSaveChangesOpen(true);
            }
          }}
        >
          {floor.DisplayName}
        </div>
        {selectedFloor?.FloorID === floor.FloorID ? (
          <div
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className='menu-ic'
          >
            <img alt='' src={menu} className='menu' />
          </div>
        ) : (
          <div className='menu-ic' />
        )}
        {isOpen && selectedFloor?.FloorID === floor.FloorID && (
          <div className='dropdown' ref={dropdownRef}>
            <div
              className='item'
              onClick={() => {
                setName(selectedFloor?.Name);
                setIsOpen(false);
                setIsRenameOpen(true);
              }}
            >
              <img alt='' src={rename} className='menu' />
              Rename Room
            </div>
            <div
              className='item'
              onClick={() => {
                setIsOpen(false);
                duplicateRoom();
              }}
            >
              <img alt='' src={copy} className='menu' />
              Duplicate Room
            </div>{" "}
            <div
              className='item'
              onClick={() => {
                setIsOpen(false);
                if (
                  selectedFloor?.Tables?.filter(
                    (table: any) => table?.Reservations?.length > 0
                  ).length > 0
                ) {
                  setIsRoomDeleteWarningOpen(true);
                } else {
                  setIsDeleteOpen(true);
                }
              }}
            >
              <img alt='' src={trash} className='menu' />
              Delete Room
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default RoomLayout;
