import React, { useEffect, useState, useRef } from "react";
import Button from "../../../../components/button/Button";
import back from "../../../../assets/arrow.png";
import "./TableDetailsStyles.scss";
import FloorLayout from "../../../../components/ReservationComponents/floorLayout/FloorLayout";
import Alert from "./Alert";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../application/store";
import SelectedTables from "./SelectedTables";
interface TableDetailsProps {
  isModalOpen: boolean;
  onClose: () => void;
  tableData: any;
  setSelectedTable: React.Dispatch<React.SetStateAction<any>>;
  date: any;
  bookingTime: string;
  guest: number;
}
const TabelDetails: React.FC<TableDetailsProps> = ({
  isModalOpen,
  onClose,
  tableData,
  setSelectedTable,
  date,
  bookingTime,
  guest,
}) => {
  const floorData = useSelector((state: RootState) => state.booking.floorData);
  const allocatedTables = useSelector(
    (state: RootState) => state.booking.allocatedTables
  );
  const [selectedFloor, setSelectedFloor] = useState<any>(null);

  const [isAlertVisible, setIsAlertVisible] = useState("");
  const parentRef = useRef<HTMLDivElement>(null);
  const [parentHeight, setParentHeight] = useState(540);

  const getMaxCapacitySum = () => {
    return allocatedTables.reduce((sum, table) => sum + table.Capacity.Max, 0);
  };
  const getMinCapacitySum = () => {
    return allocatedTables.reduce((sum, table) => sum + table.Capacity.Max, 0);
  };
  useEffect(() => {
    if (allocatedTables.length === 0) {
      setIsAlertVisible("danger");
    } else if (getMaxCapacitySum() < guest && allocatedTables.length > 0) {
      setIsAlertVisible("warning");
    } else if (getMinCapacitySum() / 2 > guest && allocatedTables.length > 0) {
      setIsAlertVisible("warning-2");
    } else {
      setIsAlertVisible("");
    }
  }, [guest, allocatedTables]);
  useEffect(() => {
    if (allocatedTables.length === 0) {
      setIsAlertVisible("danger");
    } else if (getMaxCapacitySum() < guest && allocatedTables.length > 0) {
      setIsAlertVisible("warning");
    } else if (getMinCapacitySum() / 2 > guest && allocatedTables.length > 0) {
      setIsAlertVisible("warning-2");
    } else {
      setIsAlertVisible("");
    }
  }, [allocatedTables]);
  useEffect(() => {
    if (floorData) {
      setSelectedFloor(floorData[0]);
    }
  }, []);
  useEffect(() => {
    const parent = parentRef.current;
    if (selectedFloor?.Tables?.length > 0) {
      if (parent) {
        const isOverflowingHeight = parent.scrollHeight > parent.clientHeight;
        if (isOverflowingHeight) {
          setParentHeight((prevHeight) => prevHeight + 540);
        }
      }
    } else {
      setParentHeight(540);
    }
  }, [selectedFloor, parentHeight]);
  const getAlert = () => {
    if (isAlertVisible === "danger") {
      return <Alert type='danger' content='You need to select a table.' />;
    } else if (isAlertVisible === "warning") {
      return <Alert type='warning' content={`Tables are over utilized.`} />;
    } else if (isAlertVisible === "warning-2") {
      return (
        <Alert
          type='warning'
          content={`Tables are underutilized. Maximum capacity is ${getMaxCapacitySum()}`}
        />
      );
    }
  };
  const saveChanges = () => {
    if (
      allocatedTables.length > 0 &&
      (isAlertVisible === "" || isAlertVisible !== "danger")
    ) {
      let newTableDate = {
        ...tableData,
        AssetAllocation: allocatedTables,
      };
      setSelectedTable(newTableDate);
      onClose();
    }
  };
  return (
    <>
      <div className='TableDetails-overlay'>
        <div
          className='TableDetails-content'
          onClick={(e) => e.stopPropagation()}
        >
          <div className='header-container-table'>
            <div className='header'>
              <img alt='' src={back} onClick={onClose} />
              Select Tables
            </div>
            <div className='button-container'>
              <Button
                auth
                text='Save'
                height='40px'
                padding='0.75rem 2rem'
                onClick={saveChanges}
                disable={allocatedTables.length === 0}
              />
            </div>
          </div>
          <div className='tab-bar-table'>
            {floorData.map((floor: any) => {
              return (
                <div
                  className={`item ${
                    selectedFloor?.FloorID === floor.FloorID ? "selected " : ""
                  }`}
                  onClick={() => setSelectedFloor(floor)}
                >
                  {floor.DisplayName}
                </div>
              );
            })}
          </div>
          <div className='floor-view-table'>
            {isAlertVisible !== "" && getAlert()}

            <div
              className='floor'
              ref={parentRef}
              style={{ height: `${parentHeight}px` }}
            >
              <FloorLayout
                items={
                  selectedFloor
                    ? selectedFloor?.Tables !== null
                      ? selectedFloor?.Tables
                      : []
                    : []
                }
                time={bookingTime}
                isEdit={true}
                selected={tableData}
                isNew={false}
              />
            </div>
            <SelectedTables />
          </div>
        </div>
      </div>
    </>
  );
};
export default TabelDetails;
