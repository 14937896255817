import React from "react";
import "./ModalStyles.scss";

interface SavingModalProps {
  isOpen: boolean;
}

const SavingModal: React.FC<SavingModalProps> = ({ isOpen }) => {
  if (!isOpen) {
    return null;
  }

  return <div className='modal-overlay'>Saving ...</div>;
};

export default SavingModal;
