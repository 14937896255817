import React from "react";
import "./TagModalStyles.scss";
import close from "../../../../assets/close.png";
import arrow from "../../../../assets/grd-arr.svg";

import { useSelector } from "react-redux";
import { RootState } from "../../../../../application/store";

interface TagModalProps {
  isOpen: boolean;
  onClose: () => void;
  tags: any[];
  setTags: React.Dispatch<React.SetStateAction<any[]>>;
  isEdit: boolean;
}

const TagModal: React.FC<TagModalProps> = ({
  isOpen,
  onClose,
  setTags,
  tags,
  isEdit,
}) => {
  const bookingTags = useSelector(
    (state: RootState) => state.booking.bookingTags
  );

  if (!isOpen) {
    return null;
  }

  return (
    <div className='tag-modal-overlay' onClick={onClose}>
      <div
        className='tag-modal-content'
        onClick={(e) => e.stopPropagation()}
        style={{ left: isEdit ? "37.2%" : "", top: isEdit ? "2rem" : "" }}
      >
        <div className='header'>
          <img alt='' src={close} onClick={onClose} />
        </div>
        <div className='content'>
          {bookingTags.map((status, index) => {
            return (
              <div key={index}>
                <div className='cat'>
                  {status.Name} <img alt='' src={arrow} />
                </div>
                {status.Tags.length > 0 && (
                  <div className='items'>
                    {status.Tags.map((item: any, indexKey: number) => {
                      return (
                        <div
                          className='item-cat'
                          key={indexKey}
                          style={{
                            color: item.Color,
                            backgroundColor: `${item.Color}1A`,
                          }}
                          onClick={() => {
                            setTags([...tags, item]);
                            onClose();
                          }}
                        >
                          <img alt='' src={item.Image} />
                          {item.DisplayName}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TagModal;
