import React, { useState, useEffect, useRef } from "react";
import "./BlockDropdownStyles.scss";
import block from "../../../assets/block.png";
import clock from "../../../assets/clock.png";
import table from "../../../assets/table-blo.png";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BlockingHourModal from "../blockingModal/BlockingHourModal/BlockingHourModal";
import {
  setIsBlockedOpen,
  setIsBlockedTableOpen,
} from "../../../../application/reducer/bookingSlice";

interface BlockDropdownProps {}

const BlockDropdown: React.FC<BlockDropdownProps> = ({}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleToggle = () => setIsOpen(!isOpen);

  // Close the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className='BlockDropdown' ref={dropdownRef}>
      <div className='BlockDropdown-cont' onClick={handleToggle}>
        <img alt='' src={block} />
        <div className='dropdown-button'>Manage Restrictions</div>
      </div>

      {isOpen && (
        <div className='dropdown-content'>
          <div
            className='location '
            onClick={() => {
              dispatch(setIsBlockedOpen(true));
            }}
          >
            <img alt='' src={clock} className='clock' />
            Blocking Hours
          </div>
          <div
            className='location disabled'
            onClick={() => {
              dispatch(setIsBlockedTableOpen(true));
            }}
          >
            <img alt='' src={table} className='table' />
            Blocking Tables
          </div>
        </div>
      )}
    </div>
  );
};

export default BlockDropdown;
