import React from "react";
import "./ModalStyles.scss";
import Button from "../../button/Button";

interface TableErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
  content: string;
}

const TableErrorModal: React.FC<TableErrorModalProps> = ({
  isOpen,
  onClose,
  content,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className='modal-overlay' onClick={onClose}>
      <div className='modal-content' onClick={(e) => e.stopPropagation()}>
        <div className='header'>Reservation Overlapping</div>
        <div className='content'>{content}</div>
        <div className='btn-container'>
          <Button text='OK' onClick={onClose} />
        </div>
      </div>
    </div>
  );
};

export default TableErrorModal;
